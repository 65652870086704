// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.registration_input_title {
  font-family: var(--main-font);
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color: var(--font-color);
}

.registration_input {
  border: 1px solid rgba(82, 82, 82, 1);
  border-radius: 100px;
  color: var(--font-color);
  font-family: var(--second-font);
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  padding-left: 20px;
  width: 548px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 20px;
  background-color: var(--bg-color);
}

.registration_input::placeholder {
  color: rgba(82, 82, 82, 1);
}

@media (min-width: 0px) and (max-width: 743px) {
  .registration_input {
    width: 298px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/RegistrationInput/style.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,qCAAqC;EACrC,oBAAoB;EACpB,wBAAwB;EACxB,+BAA+B;EAC/B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,iCAAiC;AACnC;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE;IACE,YAAY;EACd;AACF","sourcesContent":[".registration_input_title {\r\n  font-family: var(--main-font);\r\n  font-size: 22px;\r\n  font-weight: 700;\r\n  line-height: 26px;\r\n  color: var(--font-color);\r\n}\r\n\r\n.registration_input {\r\n  border: 1px solid rgba(82, 82, 82, 1);\r\n  border-radius: 100px;\r\n  color: var(--font-color);\r\n  font-family: var(--second-font);\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n  line-height: 19px;\r\n  padding-left: 20px;\r\n  width: 548px;\r\n  height: 50px;\r\n  margin-top: 10px;\r\n  margin-bottom: 20px;\r\n  background-color: var(--bg-color);\r\n}\r\n\r\n.registration_input::placeholder {\r\n  color: rgba(82, 82, 82, 1);\r\n}\r\n\r\n@media (min-width: 0px) and (max-width: 743px) {\r\n  .registration_input {\r\n    width: 298px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
