import React, { useState } from 'react';
import PasswordInput from '../PasswordInput';
import PhoneNumberInput from '../PhoneNumberInput';
import RegistrationInput from '../RegistrationInput';

import './style.css';
import { href } from '../../pages/href.js'

// Компонент, отображающий регистрацию

function Registration({ handleRegistrationButtonClick, setRegistrationState, setNumber }) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [login, setLogin] = useState('')
  const [loginError, setLoginError] = useState('')
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');

  const mockTextFileForUsers = '/CategoryPanelAssets/home.svg'; // Файл, который будет скачен при нажатии на пользовательское соглашение

  const handleRegistrationSubmit = async (event) => {
    event.preventDefault();
  
    if (passwordError || phoneNumberError) {
      return;
    }

    const registrationData = {
      username: login,
      password: password,
      phonenumber: phoneNumber,
    };

    try {
      const response = await fetch(href + 'registration', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationData),
    });

    const result = await response.json();
    if (response.ok) {
      if (result.msg) { // Имя, при котором сработает плашка ошибки
        setLoginError('Данный логин занят');
        return;
      }

      setLoginError('');
      localStorage.setItem('jwt', result.access_token);  // Сохранение токена в localStorage
      console.log('Регистрация прошла успешно');
      setNumber(phoneNumber)
      handleRegistrationButtonClick();
      } else {
        throw new Error(result.msg);
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  };
  

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className='registration_container'>
      <h2 className='registration_title'>Регистрация</h2>
      <form onSubmit={handleRegistrationSubmit}>
        <RegistrationInput
          value={login}
          setValue={setLogin}
          error={loginError}
          title={'Введите имя'}
          type={'text'}
        />
        <PasswordInput
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
        />
        <PhoneNumberInput
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          phoneNumberError={phoneNumberError}
          setPhoneNumberError={setPhoneNumberError}
        />

        <button
          type='submit'
          className='registration_submit_button'
        >ЗАРЕГИСТРИРОВАТЬСЯ</button>
      </form>
      <div className='registration_text_container'>
        <p className='registration_text'>Уже есть аккаунт?</p>
        <p onClick={() => setRegistrationState('login')} className='registration_text_description'>Войти</p>
      </div>
      <button className='registration_tg_button' onClick={() => openInNewTab('https://telegram.org')}>Зарегистрироваться с помощью телеграм</button>
      <button className='registration_vk_button' onClick={() => openInNewTab('https://vk.com')}>Зарегистрироваться с помощью вконтакте</button>
      <p className='mobile_text_description'>Зарегестрироваться с помощью</p>
      <div className='mobile_bnts_container'>
        <button className='registration_tg_button_mobile' onClick={() => openInNewTab('https://telegram.org')}></button>
        <button className='registration_vk_button_mobile' onClick={() => openInNewTab('https://vk.com')}></button>
      </div>
      <a className='donwload_text' href={mockTextFileForUsers} download>Пользуясь нашим сервисом, вы соглашаетесь <br></br> с <u>пользовательским соглашением</u></a>
    </div>
  );
}

export default Registration;
