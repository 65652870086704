import { createContext, useContext, useState } from 'react';

const UserLoggedContext = createContext();

// Контекст, который позоляет менять состояние входа пользовотеля во всем приложении

export const UserLoggedProvider = ({ children }) => {
  const [isUserLogged, setIsUserLogged] = useState(false);

  return (
    <UserLoggedContext.Provider value={{ isUserLogged, setIsUserLogged }}>
      {children}
    </UserLoggedContext.Provider>
  );
};

export const useUserLogged = () => {
  const context = useContext(UserLoggedContext);

  return context;
};
