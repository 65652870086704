import React from 'react';

import './style.css';

// Контейнер для модального окна

function ModalContentContainer({ onClose, children }) {
  const stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div className='modal' onClick={onClose}>
      <div className='modal_wrapper' onClick={stopPropagation}>
        {children}
      </div>
    </div>
  );
}

export default ModalContentContainer;
