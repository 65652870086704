import React from 'react';
import CategoryPanel from '../../components/CategoryPanel';
import Header from '../../components/Header';
import Slider from '../../components/Slider';
import DonatersList from '../../components/DonatersList';
import ProjectFiles from '../../components/ProjectFiles';
import DonationInfo from '../../components/DonationInfo';
import EducationalInfo from '../../components/EducationalInfo';
import ProjectVideos from '../../components/ProjectVideos';
import Loader from '../../components/Loader';
import { useCategoryPanelContext } from '../CategoryPanelContext';
import useProjectData from '../../pages/Project/useProjectData';
import { useUserLogged } from '../../pages/Home/useUserLoggedState';
import { ReactComponent as Arrow } from './assets/arrow.svg'
import { ReactComponent as Hands } from './assets/hands.svg'
import { ReactComponent as Info } from './assets/info.svg'

import './style.css';

// Декстоп версия ProjectPage

function ProjectPageDesktop({ ProfileIcon }) {
  const { loading, project, goToUserProfile, goToLink, goToSearchPage, handleLike,
    liked, isModalOpen, closeModal, openModal } = useProjectData();
  const { setShowCategoryPanel } = useCategoryPanelContext();
  const {isUserLogged, setIsUserLogged} = useUserLogged();

  return (
    <div className='project_page_container'>
      <CategoryPanel visible={true} setShowCategoryPanel={setShowCategoryPanel}/>
      <div className='project_page_wrapper'>
        <Header isLarge={false} isUserLogged={isUserLogged} isNotificationOpen={isModalOpen} setIsUserLogged={setIsUserLogged} closeNotificationModal={closeModal} ProfileIcon={ProfileIcon}/>
        <div className='project_page_content_wrapper'>
          {loading && <Loader loaderText='Загружаем проект'/>}
          {!loading && (
            <>
              <div className='project_page_left_container'>
                <div className='project_page_account_info'>
                  <h3 className='project_page_title'>{project.title}</h3>
                  {project.educational && <p className='project_page_account_type'>УЧЕБНЫЙ ПРОЕКТ</p>}
                </div>
                <div className='tags_container'>
                  {project.tags.map((tag, index) => (
                    <p className='tag' onClick={() => goToSearchPage(tag)} key={index}>{tag}</p>
                  ))}
                </div>
                <div className='links_container'>
                  {project.links.map((link, index) => (
                    <div className='links_wrapper' key={index} onClick={() => goToLink(link)}>
                      <div className='link_img'>
                        <Arrow />
                      </div>
                      <p className='link_text'>{link}</p>
                    </div>
                  ))}
                </div>
                <div className='likes_container'>
                  <div
                    className='like_img'
                    onClick={handleLike}
                  >
                    {liked ? <Hands /> : <img src='/ProjectPageAssets/hands_liked.svg' alt='hands'/>}
                  </div>
                  <p className='like_text'>{project.likes} оценили</p>
                </div>
                <Slider images={project.projectImages}></Slider>
                <ProjectVideos projectVideos={project.projectVideos} />
                <p className='project_text_description'>{project.description}</p>
                <h6 className='project_title_text'>Цель по развитию</h6>
                <p className='project_text_description'>{project.goalText}</p>
                {!project.educational && <h6 className='project_title_text'>Что получите за донат</h6>}
                {!project.educational && <p className='project_text_description'>{project.donatText}</p>}
                {project.presentation && <h5 className='presentation_text'>Презентация проекта</h5>}
                {project.presentation && <div className='presentation_container'>
                  <a className='presentation_container' href={project.presentation.title} download>
                    <img className='doc_img' src={'/ProjectPageAssets/file.png'} alt='presentation'></img>
                    <div>
                        <h6 className='presentation_title'>{project.presentation.title.substring(project.presentation.title.lastIndexOf('/') + 1)}</h6>
                        <p className='presentation_size'>{project.presentation.size}</p>
                    </div>
                  </a>
                </div>}
                {project.anotherFiles && <ProjectFiles anotherFiles={project.anotherFiles} />}
              </div>
              <div className='project_page_right_container'>
                <div className='project_page_author_container'>
                  <img onClick={goToUserProfile} src={project.authorImg} alt='author' className='project_page_author'></img>
                  <p onClick={goToUserProfile} className='project_page_author_name'>{project.author}</p>
                  <div>
                    <Info />
                  </div>
                  <button onClick={openModal} className='report_button'>ПОЖАЛОВАТЬСЯ</button>
                </div>
                {!project.educational && <DonationInfo 
                  donatsCount={project.donatsCount}
                  donatsSum={project.donatsSum}
                  startGoal={project.startGoal}
                  finalGoal={project.finalGoal}
                  title={project.title}
                />}
                {project.educational && <EducationalInfo 
                  donatsCount={project.donatsCount}
                  donatsSum={project.donatsSum}
                  projectName={project.title}
                />}
                <DonatersList donaters={project.donaters}/>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ProjectPageDesktop;
