import { useState, useRef } from 'react';

import { href } from '../../pages/href.js'
import './style.css';

// Компонент, который отображает ввод кода при регистрации

function CodeInput({ handleCodeSubmit, phoneNumber }) {
  const [code, setCode] = useState(['', '', '', '']);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const inputsRef = useRef([]);
  const [codeError, setCodeError] = useState('')

  const handleChange = (index) => (e) => {
    setCodeError('')
    const newCode = [...code];
    const { value } = e.target;
    if (/^\d?$/.test(value)) {
      newCode[index] = value;
      setCode(newCode);

      if (value && index < code.length - 1) {
        inputsRef.current[index + 1].focus();
      }
    }
    if (isSubmitAttempted) setIsSubmitAttempted(false);
  };

  const handleKeyDown = (index) => (e) => {
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      inputsRef.current[index - 1].focus();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Сообщение о неправильно введеном коде при всех еденицах
    if (code.every((element, index) => element === '1' && index < 4)) {
      setCodeError('Неверный код')
      return
    }
    if (code.every(digit => digit)) {
      const fetchData = async (event) => {
        const settingsData = {
          phoneNumber: phoneNumber,
          code: code.join(''),
        };

        try {
          const response = await fetch(href + 'verificate_code', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(settingsData),
        });

          const result = await response.json();
          if (response.ok) {
            handleCodeSubmit();
            setIsSubmitAttempted(false);
          } else {
            throw new Error(result.msg);
           }
        } catch (error) {
          console.error('Ошибка:', error);
        }
      }
      fetchData();
    } else {
      setIsSubmitAttempted(true);
    }
  };

  return (
    <form onSubmit={handleSubmit} className='code_input_container'>
      <h2 className='registration_title'>Регистрация</h2>
      <p className='code_input_title'>Введите код</p>
      <p className='code_input_description'>Сейчас на Ваш номер телефона поступит звонок. Введите последние 4 цифры звонившего номера.</p>
      <div className='code_inputs_container'>
        {code.map((digit, index) => (
          <input
            ref={(el) => inputsRef.current[index] = el}
            key={index}
            type='text'
            maxLength='1'
            value={digit}
            onChange={handleChange(index)}
            onKeyDown={handleKeyDown(index)}
            className='digit_input'
            autoFocus={index === 0}
          />
        ))}
      </div>
      {isSubmitAttempted && <p className='error_message'>Пожалуйста, заполните все поля.</p>}
      {codeError && <p className='error_message'>{codeError}</p>}
      <button
        type='submit'
        className='registration_submit_button'
      >ПРОВЕРИТЬ КОД ПОДТВЕРЖДЕНИЯ</button>
    </form>
  );
}

export default CodeInput;
