import { useState, useEffect } from 'react';
import ImagesInput from '../../components/ImagesInput';
import ExpandableSection from '../../components/ExpandableSection';
import Loader from '../../components/Loader';
import Header from '../../components/Header';
import ModalContentContainer from '../../components/ModalContentContainer';
import ModalCategories from '../../components/ModalCategories';
import useWindowWidthPanel from '../../components/CategoryPanel/useWindowWidth';
import CategoryPanel from '../../components/CategoryPanel';
import FileInput from '../../components/FileInput';
import useLockBodyScroll from '../../hooks/useBodyLock';
import { useNavigate } from 'react-router-dom';

import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useUserLogged } from '../Home/useUserLoggedState';
import { notifications } from '../../components/NotificationContainer/notifications';
import { href } from '../href.js'


import './style.css';

function CreateProjectPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('Видеоигры');
  const [isEducational, setIsEducational] = useState(false);
  const [isNoValidate, setIsNoValidate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [buttons, setButtons] = useState([]);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false)
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const [projectName, setProjectName] = useState('')
  const [projectNameError, setProjectNameError] = useState('')
  const navigate = useNavigate();
  const [ProfileIcon, setProfileIcon] = useState('')
  const [ tags, setTags] = useState('')
  const [ projectCap, setProjectCap ] = useState('')
  const [ projectFotos, setProjectFotos] = useState('')
  const [ projectDocuments, setProjectDocuments] = useState('')
  const [ specialFiles, setSpecialFiles ] = useState('')
  const [ description, setDescription] = useState('')
  const [ links, setLinks ] = useState([])
  const [ purpose, setPurpose ] = useState('')
  const [ money, setMoney ] = useState('')
  const [ achievement, setAchievement ] = useState('')

  const [fileInputSettings, setFileInputSettings] = useState({
    photosFileInput: { maxFiles: 5, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
    docsFileInput: { maxFiles: 2, allowedTypes: ['application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
      'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
    specialFilesInput: { maxFiles: 5, allowedTypes : [
      'application/acad',
      'application/x-acad',
      'application/autocad_dwg',
      'image/x-dwg',
      'application/dwg',
      'application/x-dwg',
      'application/x-autocad',
      'image/vnd.dwg',
      'drawing/dwg',
      'application/sldprt',
      'application/sldasm',
      'application/catpart',
      'application/catproduct'] },
  });

  const [buttonsCount, setButtonsCount] = useState(3)


  const visible = useWindowWidthPanel()

  const handleAddButton = () => {
    if (buttons.length < buttonsCount) {
      setButtons(prevButtons => [
        ...prevButtons,
        <input key={prevButtons.length} className='create_project_input' onChange={(e) => setLinks(prevFiles => [...prevFiles, e.target.value])} placeholder='Вставьте ссылку на видео или ресурс' />
      ]);
    }
  };
  

  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    closeModal();
    // В зависимости от категории у инпутов будет меняться количество файлов и допустимые типы
    switch (category) {
      case 'Видеоигры':
        setFileInputSettings({
          photosFileInput: { maxFiles: 5, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 2, allowedTypes: ['application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(3)
        break;
      case 'ПО':
        setFileInputSettings({
          photosFileInput: { maxFiles: 5, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 2, allowedTypes: ['application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(3)
        break;
      case 'Инженерные проекты':
        setFileInputSettings({
          photosFileInput: { maxFiles: 5, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 2, allowedTypes: ['application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf',
            'application/acad',
            'application/x-acad',
            'application/autocad_dwg',
            'image/x-dwg',
            'application/dwg',
            'application/x-dwg',
            'application/x-autocad',
            'image/vnd.dwg',
            'drawing/dwg',
            'application/sldprt',
            'application/sldasm',
            'application/catpart',
            'application/catproduct']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(4)
        break;
      case 'Web приложения':
        setFileInputSettings({
          photosFileInput: { maxFiles: 3, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 5,allowedTypes: ['application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(3)
        break;
      case 'Творчество':
        setFileInputSettings({
          photosFileInput: { maxFiles: 10, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 2, allowedTypes: ['application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(6)
        break;
      case 'Социальные проекты':
        setFileInputSettings({
          photosFileInput: { maxFiles: 3, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 3, allowedTypes: ['application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        setButtonsCount(4)
        break;
      default:
        setFileInputSettings({
          photosFileInput: { maxFiles: 5, allowedTypes: ['image/jpeg', 'image/png', 'image/svg+xml'] },
          docsFileInput: { maxFiles: 3, allowedTypes: ['application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/vnd.ms-powerpoint', 'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf']},
          specialFilesInput: { maxFiles: 1,allowedTypes : ['application/msword', 'application/vnd.ms-powerpoint', 
            'application/vnd.openxmlformats-officedocument.presentationml.presentation', 'application/pdf'
            ]}
        });
        break;
    }
  };

  const openModal = (event) => {
    event.preventDefault()
    setIsModalOpen(true);
    setIsNoValidate(true)
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setIsNoValidate(false)
  };

  const toggle = () => {
    setIsEducational(!isEducational)
  }

  const handleCreateProject = (event) => {
    event.preventDefault()

    setIsLoading(true);
/*
    const fetchProjectData = async (event) => {
      const projectData = {
        jwt: localStorage.jwt,
        projectName: projectName,
        tags: tags,
        category: selectedCategory,
        projectCap: projectCap,
        projectFoto: projectFoto,
        projectDocuments: projectDocuments,
        description: description,
        links: links,
        educational: isEducational
      };

      if (selectedCategory == 'Инженерные проекты') {
        projectData.append('specialFiles', specialFiles);
      }

      if (!isEducational) {
        projectData.append('purpose', purpose);
        projectData.append('money', money);
        projectData.append('achievement', achievement);
      }
         
      console.log(projectData)

 
      try {
        const response = await fetch(href + 'a', {
          method: 'POST',
          body: projectData,
        });

        const result = await response.json();
        if (response.ok) {
          setIsLoading(false);
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        setIsLoading(false);
      }
    }
    fetchProjectData();
*/
/*
    const jwt = localStorage.jwt
    axios.post(href + '/a', {jwt, projectFoto})
      .then(response =>{
      })
      .catch(erorr => {
      })
*/

     const fetchProjectData = async (event) => {
      const formData = new FormData();
      formData.append('jwt', localStorage.jwt)
      formData.append('projectCap', projectCap[0])
      formData.append('projectName', projectName)
      formData.append('tags', tags)
      formData.append('category', selectedCategory)
      formData.append('description', description)
      formData.append('educational', isEducational)
      if (!isEducational) {
        formData.append('purpose', purpose);
        formData.append('money', money);
        formData.append('achievement', achievement);
      }
      for (let i = 0; i < projectFotos.length; i++) {
        formData.append('projectFoto' + String(i), projectFotos[i])
      }
      for (let i = 0; i < projectDocuments.length; i++) {
        formData.append('projectDocument' + String(i), projectDocuments[i])
      }
      for (let i = 0; i < links.length; i++) {
        formData.append('link' + String(i), links[i])
      }
      for (let i = 0; i < specialFiles.length; i++) {
        formData.append('specialFile' + String(i), specialFiles[i])
      }
//      formData.append('', )
      try {
        const response = await fetch(href + 'get_create_project', {
          method: 'POST',
          body: formData,
        });

        const result = await response.json();
        if (response.ok) {
          if (result.msg) {
            setProjectNameError('Такой проект уже существует')
          } else {
            setTags('')
            setProjectCap('')
            setProjectFotos('')
            setProjectDocuments('')
            setSpecialFiles('')
            setDescription('')
            setLinks('')
            setPurpose('')
            setMoney('')
            setAchievement('')
            setIsEducational(false)
            setProjectName('')
            setIsLoading(false);
          }
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        setIsLoading(false);
      }
    }
    fetchProjectData();

    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  };

  const openNotificationModal = () => {
    setIsNotificationModalOpen(true)
    notifications.unshift({ text: 'Ваш проект ушел на рассмотрение' },)
  }

  const closeNotificationModal = () => {
    setIsNotificationModalOpen(false);
  }

  useLockBodyScroll(showCategoryPanel)

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt,
          };

          try {
            const response = await fetch(href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      } else{
        navigate('/')
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])


  return (
    <form className='create_project_container' onSubmit={handleCreateProject} noValidate={isNoValidate}>
      <Header
        isUserLogged={isUserLogged} isLarge={true} toggleCategoryPanel={toggleCategoryPanel}
        setIsUserLogged={setIsUserLogged} isNotificationOpen={isNotificationModalOpen} closeNotificationModal={closeNotificationModal} ProfileIcon={ProfileIcon}/>
      {isLoading ? (
        <Loader loaderText={'Создаем проект'} />
      ) : (
        <div className='create_project_main_wrapper'>
          {showCategoryPanel && <div className='dark_overlay'></div>}
          <CategoryPanel visible={visible} showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} />
          <h2 className='create_project_title'>Создание проекта</h2>
          <ExpandableSection title={'Базовая информация'} description={'Название, обложка, теги, категории'} isEducational={false}>
            <div className='create_project_inputs'>
              <div>
                <p className='create_project_description'>Название</p>
                <input className='create_project_input' value={projectName} maxLength={128} onChange={(e) => setProjectName(e.target.value)} placeholder='Например, GTA' required></input>
                {projectNameError && <p className='error_message error_project'>{projectNameError}</p> }
              </div>
              <div>
                <p className='create_project_description'>Теги</p>
                <input className='create_project_input' maxLength={128} onChange={(e) => setTags(e.target.value)} placeholder='Например,"#web #study"'></input>
              </div>
              <div>
                <p className='create_project_description'>Категория</p>
                <button className='select_category_button' onClick={openModal}>{selectedCategory}</button>
              </div>
            </div>
            <div className='create_project_cover_container'>
              <p className='create_project_description'>Обложка</p>
              <ImagesInput required={true} size='big' setFileList={setProjectCap}></ImagesInput>
            </div>
            <div className='create_project_checkbox_container'>
              <input className='custom_checkbox' type='checkbox' onClick={toggle}></input>
              <div>
                <h6 className='create_project_text'>Пометить проект как учебный</h6>
                <p className='create_project_text_description'>Учебный проекты рекомендуются за счёт оценок пользователей. Рабочие рекомендуются за счёт денежной поддержки.</p>
              </div>
            </div>
          </ExpandableSection>
          <ExpandableSection title={'Ивестиции'} description={'Описание, цели проекта'} isEducational={isEducational}>
            <div className='create_project_inputs'>
              <div>
                <p className='create_project_description'>Текущие цели проекта</p>
                <input className='create_project_input margin_right' placeholder='Цели проекта' maxLength={128} onChange={(e) => setPurpose(e.target.value)} required={!isEducational}></input>
                <input className='create_project_input number_input' type='number' placeholder='Стоимость, P' maxLength={128} onChange={(e) => setMoney(e.target.value)} required={!isEducational}></input>
                <input className='create_project_input wide' placeholder='Что получит пользователь' maxLength={128} onChange={(e) => setAchievement(e.target.value)} required={!isEducational}></input>
                <p className='goal_description'>На данный момент цель нельзя изменить.</p>
              </div>
            </div>
          </ExpandableSection>
          <ExpandableSection title={'Дополнительная информация'} description={'Фотографии, видео, документация'} isEducational={false}>
            <div className='create_project_inputs  height'>
              <div className='doc-container'>
                <p className='create_project_description'>Фотографии</p>
                <ImagesInput size='small' isImagesVisible={true} allowedTypes={fileInputSettings.photosFileInput.allowedTypes} maxFiles={fileInputSettings.photosFileInput.maxFiles} setFileList={setProjectFotos}/>
              </div>
              <div className='doc-container'>
                <p className='create_project_description'>Документы</p>
                <FileInput size='small' allowedTypes={fileInputSettings.docsFileInput.allowedTypes} maxFiles={fileInputSettings.docsFileInput.maxFiles} setFileList={setProjectDocuments}/>
              </div>
              <div>
                <p className='create_project_description'>Особые файлы</p>
                <FileInput size='small' allowedTypes={fileInputSettings.specialFilesInput.allowedTypes} maxFiles={fileInputSettings.specialFilesInput.maxFiles} setFileList={setSpecialFiles}/>
              </div>
            </div>
            <div className='create_project_inputs_description'>
              <div>
                <p className='create_project_description'>Описание</p>
                <input className='create_project_input wide' maxLength={2048} onChange={(e) => setDescription(e.target.value)} placeholder='Напишите что-то о проекте'></input>
              </div>
              <div className='buttons_container'>
                <p className='create_project_description'>Видео и ресурсы</p>
                {buttons}
                <input className='create_project_input' maxLength={128} onChange={(e) => setLinks(prevFiles => [...prevFiles, e.target.value])} placeholder='Вставьте ссылку на видео или ресурс'></input>
                {(buttons.length < buttonsCount) && <div className='add_btn' onClick={handleAddButton}>+</div>}
              </div>
            </div>
            {isModalOpen &&
              <ModalContentContainer onClose={closeModal}>
                <ModalCategories onSelect={handleCategorySelect} />
              </ModalContentContainer>
            }
          </ExpandableSection>
          <button className='create_project_button' type='submit'>Создать проект</button>
        </div>
      )}
    </form>
  );
}

export default CreateProjectPage;
