import { useEffect } from 'react';

// Хук, для того, тобы при открытии бургер меню заблокировать скролл экрана под ним

const useLockBodyScroll = (showCategoryPanel) => {
  useEffect(() => {
    const handleScrollLock = () => {
      if (showCategoryPanel) {
        document.body.classList.add('locked');
      } else {
        document.body.classList.remove('locked');
      }
    };

    handleScrollLock();

    return () => {
      document.body.classList.remove('locked');
    };
  }, [showCategoryPanel]);
};

export default useLockBodyScroll;
