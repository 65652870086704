import { useLocation } from 'react-router-dom';
import CategoryPanel from '../../components/CategoryPanel';
import Header from '../../components/Header';
import ProjectsList from '../../components/ProjectsList';
import useLockBodyScroll from '../../hooks/useBodyLock';
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useUserLogged } from '../Home/useUserLoggedState';
import { href } from '../href.js'

import './style.css';

function SearchPage() {
  const location = useLocation();
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const searchParams = new URLSearchParams(location.search);
  const searchProjectName = searchParams.get('query') || '';
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const navigate = useNavigate();
  const [ProfileIcon, setProfileIcon] = useState('')
  const [isLoading, setIsLoading] = useState(true);

  useLockBodyScroll(showCategoryPanel)

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt != ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch(href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])

  return (
    <div className='search_page_container'>
      <CategoryPanel showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} visible={true} />
      <div className='search_page_main_content'>
        {showCategoryPanel && <div className='dark_overlay'></div>}
        <Header isUserLogged={isUserLogged} isLarge={false} toggleCategoryPanel={toggleCategoryPanel} setIsUserLogged={setIsUserLogged} ProfileIcon={ProfileIcon}/>
        <div className='search_content_wrapper'>
          <h4 className='seacrh_title'>Поиск по названию {searchProjectName}</h4>
          <ProjectsList Locate={'?query=' + searchProjectName}></ProjectsList>
        </div>
      </div>
    </div>
  )
}

export default SearchPage;
