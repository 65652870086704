import React from 'react';

import './style.css'; 

// Комопнент, отображающий инпут для регистрации

function RegistrationInput ({title, type, value, setValue, error}) {

  const handleChange = (e) => {
    setValue(e.target.value)
  }

  return (
    <div className='registration_input_container'>
      <h6 className='registration_input_title'>{title}</h6>
      <input 
        className='registration_input' 
        placeholder='Введите' 
        required 
        value={value}
        type={type} 
        onChange={handleChange}
      />
      {error && <p className='error_message'>{error}</p>}
    </div>
  )
}

export default RegistrationInput;
