import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { mockProjects } from './mockProjects';
import { href } from '../../pages/href.js'

// Hook for loading project list with pagination and infinite scrolling support

function useProjectsLoader( Locate, name ) {
  const [projects, setProjects] = useState('');
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMoreProjects, setHasMoreProjects] = useState(true);
  const [mainLoaded, setMainLoaded] = useState(false);
  const location = useLocation();
  const [ counter, setCounter ] = useState(1)

  useEffect(() => {
    setCounter(1)
    setLoading(true);
    setLoadingMore(false);
    setMainLoaded(false);
    setHasMoreProjects(false);
    setProjects([]);

    const fetchData = async (event) => {
      const projectsData = {
        jwt: localStorage.jwt,
        counter: 1,
        locate: Locate,
        name: name
      };

      try {
        const response = await fetch(href + 'get_projects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectsData),
        });

        const result = await response.json();
        if (response.ok) {
          if (result.projects == projects){
            setProjects(result.projects)
            setCounter(result.counter)
          }
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }

    fetchData();

    setTimeout(() => {
      setHasMoreProjects(true);
      setMainLoaded(true);
    }, 1000);

    setLoading(false);

  }, [location.pathname]);

  useEffect(() => {
    const checkAndLoadMore = () => {
      const hasScroll = document.documentElement.scrollHeight > window.innerHeight;
      if (!hasScroll && hasMoreProjects && mainLoaded) {
        setLoadingMore(true);
      }
    };

    if (mainLoaded) {
      setTimeout(checkAndLoadMore, 200);
    }
  }, [mainLoaded, hasMoreProjects]);

  useEffect(() => {
    function handleScroll() {
      const bottomThreshold = 100;
      const isScrollAtBottom =
        document.documentElement.scrollHeight -
        window.innerHeight -
        document.documentElement.scrollTop <
        bottomThreshold;

      if (isScrollAtBottom && !loadingMore && hasMoreProjects && mainLoaded) {
        setLoadingMore(true);
      }
    }

    if (mainLoaded) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [loadingMore, hasMoreProjects, mainLoaded]);

  useEffect(() => {
    const hasScroll = document.documentElement.scrollHeight > window.innerHeight;
    if (!hasScroll && counter != -1) {
      setLoadingMore(true);
    } else {
      setLoadingMore(false);
    }
  }, [projects, mainLoaded]);

  useEffect(() => {
    if (!loadingMore) return;
    const fetchData = async (event) => {
      const projectsData = {
        jwt: localStorage.jwt,
        counter: counter,
        locate: Locate,
        name: name
      };

      try {
        const response = await fetch(href + 'get_projects', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectsData),
        });

        const result = await response.json();
        if (response.ok) {
          setCounter(result.counter)
          setProjects(prevProjects => [...prevProjects, ...result.projects]);
          setHasMoreProjects(false);
          setLoadingMore(false);
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }

    fetchData();
    setTimeout(() => {
    }, 1000);
  }, [loadingMore, projects]);
  return { projects, loading, loadingMore };
}
export default useProjectsLoader;
