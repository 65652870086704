import './style.css'

// Комопнент модального окна с ссылками в вк и тг

function ModalPersonalAccount () {
  const goToVK = () => {
    window.open('https://vk.com/inspirioncom', '_blank');
  };

  const goToTG = () => {
    window.open('https://t.me/InspirionBot', '_blank');
  };

  return (
    <>
      <h2 className='personal_account_title'>Личный кабинет</h2>
      <p className='personal_account_description'>Личный кабинет включает в себя: техподдерку,<br></br> а также расширенное взаимодействие с аккаунтом</p>
      <div className='personal_account_links_container'>
        <div className='personal_account_card'>
          <div className='card_button tg_button' onClick={goToTG}></div>
          <p className='card_description'>Перейти<br></br> в Телеграм</p>
        </div>
        <div className='personal_account_card'>
          <div className='card_button vk_button' onClick={goToVK}></div>
          <p className='card_description'>Перейти<br></br> во Вконтакте</p>
        </div>
      </div>
    </>
  )
}

export default ModalPersonalAccount