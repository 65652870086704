import React from 'react';
import ProjectItem from '../ProjectItem';
import Loader from '../Loader';
import useProjectsLoader from './useProjectsLoader';

import './style.css';

// Компонент, отображающий список проектов

function ProjectsList({ Locate, name }) {
  const { projects, loading, loadingMore } = useProjectsLoader(Locate, name);

  return (
    <>
      <div className='projects_list_wrapper'>
        {loading && <Loader loaderText='Загружаем проекты' />}
        {!loading &&
          projects.map(({ title, likes, img, authorImg, author}, index) => (
            <ProjectItem
              key={index}
              title={title}
              likes={likes}
              img={img}
              authorImg={authorImg}
              author={author}
            />
          ))}
        {!loading && projects.length === 0 && <p>Проектов нет</p>}
      </div>
      {loadingMore && <Loader loaderText='Загружаем проекты' />}
    </>
  );
}

export default ProjectsList;
