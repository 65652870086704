import React, { useState } from 'react';
import RegistrationInput from '../RegistrationInput';

import './style.css';
import { href } from '../../pages/href.js'

// Компонент, отвечающий за вход пользователя

function Login ({setRegistrationState, handleLogin}) {
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [login, setLogin] = useState('')

  const handleSubmit = async (event) => {
    event.preventDefault();
    const loginData = {
      username: login,
      password: password,
    };

    try {
      const response = await fetch(href + 'login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();
        if (response.ok) {
          const not_wright = false
          if (not_wright) { // Отображение плашки неверного пароля или логина при имени Иван
            setPasswordError('Неверный логин или пароль')
            return;
          }
          setPasswordError('')
          localStorage.setItem('jwt', result.access_token);  // Сохранение токена в localStorage
          console.log('Вход выполнен успешно');
          setPassword('')
          handleLogin();
        } else {
          throw new Error(result.msg);
        }
    } catch (error) {
      console.error('Ошибка:', error);
    }
  }

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };


  return (
    <div className='login_container'>
      <h2 className='registration_title'>Войти</h2>
      <form onSubmit={handleSubmit}>
        <RegistrationInput
          value={login}
          setValue={setLogin}
          title={'Введите имя'}
          type={'text'}
        />
         <RegistrationInput
          value={password}
          setValue={setPassword}
          error={passwordError}
          title={'Введите пароль'}
          type={'password'}
        />

        <button
          className='registration_submit_button'
          type="submit" 
        >ВОЙТИ</button>
      </form>
      <div className='registration_text_container login'>
        <p className='registration_text'>Нет аккаунта?</p>
        <p onClick={() => setRegistrationState('registration')} className='registration_text_description'>Зарегистрироваться</p>
      </div>
      <button className='registration_tg_button' onClick={() => openInNewTab('https://telegram.org')}>Войти с помощью телеграм</button>
      <button className='registration_vk_button' onClick={() => openInNewTab('https://vk.com')}>Войти с помощью вконтакте</button>
      <p className='mobile_text_description'>Войти с помощью</p>
      <div className='mobile_bnts_container'>
        <button className='registration_tg_button_mobile' onClick={() => openInNewTab('https://telegram.org')}></button>
        <button className='registration_vk_button_mobile' onClick={() => openInNewTab('https://vk.com')}></button>
      </div>
    </div>
  );
}

export default Login;
