import React, { useEffect, useState } from 'react';
import ProjectPageTabletAndMobile from '../../components/ProjectPageTabletAndMobile';
import ProjectPageDesktop from '../../components/ProjectPageDesktop';
import useWindowWidth from '../../hooks/useWindowWidth';
import { useUserLogged } from '../Home/useUserLoggedState';
import { useNavigate } from 'react-router-dom';

import { href } from '../href.js'

function ProjectPage() {
  const screenWidth = useWindowWidth();
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const [ProfileIcon, setProfileIcon] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const iconData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch(href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(iconData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])

  // В зависимости от экана будет отображена та или иная версия

  return (
    <>
      {screenWidth > 0 && screenWidth < 1200 ? <ProjectPageTabletAndMobile ProfileIcon={ProfileIcon} setIsLoading={setIsLoading} /> : <ProjectPageDesktop ProfileIcon={ProfileIcon} setIsLoading={setIsLoading}/>}
    </>
  );
}

export default ProjectPage;
