import React, { useEffect, useState } from 'react';

import './style.css'

function FileInput( {setProfileCap, size, ProfileCap, required = false} ) {
  const [fileError, setFileError] = useState('');
  const [coverImage, setCoverImage] = useState(ProfileCap);
  const [fileInputText, setFileInputText] = useState('Перетащите или Загрузите изображение');

  useEffect(()=> {
    if (coverImage == '') {
      setCoverImage(null)
    }
  },[])

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        setFileError('Разрешены только файлы с расширением .jpeg, или .jpg., или .png');
        event.target.value = '';
      } else if (file.size > 5 * 1024 * 1024) {
        setFileError('Размер файла не должен превышать 5 МБ.');
        event.target.value = '';
      } else {
        setFileError('');
        setCoverImage(URL.createObjectURL(file));
      }
    }
    setProfileCap(file)
  };

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
  
      if (screenWidth < 1200 && screenWidth > 743) {
        setFileInputText('Загрузите изображение');
      } else {
        setFileInputText('Перетащите или Загрузите изображение');
      }
    }
  
    handleResize();
    window.addEventListener('resize', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  

  return (
    <div
      className={`file_input_wrapper ${size}_container ${coverImage ? '' : 'default-background'}
        ${fileError ? 'small_margin' : ''}
        ${fileError && coverImage? 'large_margin' : ''}`}
      style={{ backgroundImage: `url(${coverImage})` }}
    >
      <input type='file' onChange={handleFileChange} className={`image_input ${size}_input`} required={required} />
      <div className={`file_input_content ${size}_content ${coverImage ? 'transparent' : ''}`}>
        <img className={`${size}_img`} src='/UserProfileSettingsAssets/file_input.svg' alt='input' />
        <div className={`file_input_text_container ${size}_text_container`}>
          <p className={`file_input_text ${size}_text`}>{fileInputText}</p>
          <p className={`file_input_description ${size}_text`}>размер до 5МБ, формат .jpg, .jpeg</p>
        </div>
      </div>
      {fileError && <p className={`file_error ${size}_error`}>{fileError}</p>}
    </div>
  );
}

export default FileInput;
