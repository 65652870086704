import React, { useState, useEffect, useRef } from 'react';

import './style.css';

// Компонент, отображающий слайдер фото

function Slider({ images }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = useRef(null);

  const goToPrevSlide = () => {
    if (!isModalOpen) {
      setCurrentIndex((currentIndex - 1 + images.length) % images.length);
    }
  };
  
  const goToNextSlide = () => {
    if (!isModalOpen) {
      setCurrentIndex((currentIndex + 1) % images.length);
    }
  };
  

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClick = () => {
    openModal();
    
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (modalRef.current) {
      closeModal();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isModalOpen]);

  useEffect(() => {
    const interval = setInterval(goToNextSlide, 3000); 

    return () => clearInterval(interval); 
  }, [isModalOpen, currentIndex]); 

  const touchStartXRef = useRef(0);
  const touchEndXRef = useRef(0);

  const handleTouchStart = (e) => {
    touchStartXRef.current = e.touches[0].clientX; 
  };

  const handleTouchMove = (e) => {
    touchEndXRef.current = e.touches[0].clientX;
  };

  const handleTouchEnd = () => {
    if (touchStartXRef.current - touchEndXRef.current > 50) {
      goToNextSlide();
    } else if (touchStartXRef.current - touchEndXRef.current < -50) {
      goToPrevSlide();
    }
  };

  return (
    <div className='slider_container'>
      <div className='slider'>
        <img
          src={images[currentIndex]}
          alt={`Slide ${currentIndex}`}
          className='slide'
          onClick={handleModalClick}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        />
      </div>
      <div className='navigation'>
        <div className='arrows'>
          <div className='left_arrow' onClick={goToPrevSlide}></div>
          <div className='dots'>
            {images.map((image, index) => (
              <span key={index} className={`dot ${index === currentIndex ? 'active' : ''}`}></span>
            ))}
          </div>
          <div className='right_arrow' onClick={goToNextSlide}></div>
        </div>
      </div>
      {isModalOpen && (
        <div className='slider_modal' ref={modalRef}>
          <div className='modal_content'>
            <img src={images[currentIndex]} alt={`Slide ${currentIndex}`} className='modal_image' />
            <button className='modal_close_button' onClick={closeModal}>X</button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Slider;
