import { ReactComponent as Code } from '../CategoryPanel/assets/code.svg';
import { ReactComponent as Home } from '../CategoryPanel/assets/home.svg';
import { ReactComponent as Cube } from '../CategoryPanel/assets/cube.svg';
import { ReactComponent as Cpu } from '../CategoryPanel/assets/cpu.svg';
import { ReactComponent as Draw } from '../CategoryPanel/assets/draw.svg';
import { ReactComponent as Computer } from '../CategoryPanel/assets/computer.svg';
import { ReactComponent as User } from '../CategoryPanel/assets/user.svg';
import { ReactComponent as Star } from '../CategoryPanel/assets/star.svg';

export const categories = [
  {
    categoryTitle: 'Все категории',
    img: <Home />,
    url: '/'
  },
  {
    categoryTitle: 'Видеоигры',
    img: <Cube />,
    url: '/videogames'
  },
  {
    categoryTitle: 'ПО',
    img:  <Code />,
    url:'/software'
  },
  {
    categoryTitle: 'Инженерные проекты',
    img: <Cpu />,
    url: '/engineering_projects'
  },
  {
    categoryTitle: 'Творчество',
    img: <Draw />,
    url: '/creation'
  },
  {
    categoryTitle: 'Web-приложение',
    img: <Computer />,
    url: '/web_apps'
  },
  {
    categoryTitle: 'Социальные проекты',
    img: <User />,
    url: '/social_projects'
  },
  {
    categoryTitle: 'Лучшие',
    img: <Star />,
    url: '/best_projects'
  },
];