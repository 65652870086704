import React, { useState, useEffect } from 'react';

import './style.css'; 

const Select = ({ options, title, setValue, val }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const toggling = () => setIsOpen(!isOpen);
  
  useEffect(()=> {
    if (val != ''){
      setSelectedOption(val)
    }
  },[])

  const onOptionClicked = value => () => {
    setSelectedOption(value);
    setValue(value)
    setIsOpen(false);
  };


  return (
    <div className='select_container'>
      <h4 className='select_title'>{title}</h4>
      <div className='custom_select_container'>
       <div className={`custom_select_content ${isOpen ? 'selected_option' : ''}`} onClick={toggling}>
          {selectedOption}
         <div className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</div>
        </div>
        {isOpen && (
         <div className='custom_options'>
            {options.map(option => (
              <div key={option} onClick={onOptionClicked(option)} className='custom_option'>
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
