import BestProjectsPage from '../../pages/BestProjects';
import CreateProjectPage from '../../pages/CreateProject';
import HomePage from '../../pages/Home';
import PaymentPage from '../../pages/Payment';
import ProjectPage from '../../pages/Project';
import RegistrationPage from '../../pages/Registration';
import SearchPage from '../../pages/Search';
import UserProfilePage from '../../pages/UserProfile';
import UserProfileSettingsPage from '../../pages/UserProfileSettings';

// Роуты приложения

export const routes = [
  { path: '/', element: <HomePage /> },
  { path: '/user_profile_settings', element: <UserProfileSettingsPage /> },
  { path: '/registration', element: <RegistrationPage /> },
  { path: '/search', element: <SearchPage /> },
  { path: '/user_profile', element: <UserProfilePage /> },
  { path: '/create_project', element: <CreateProjectPage /> },
  { path: '/projects/:title', element: <ProjectPage /> },
  { path: '/best_projects', element: <BestProjectsPage /> },
  { path: '/:category', element: <HomePage /> },
  { path: '/payment', element: <PaymentPage />}
];