import React, { useEffect, useState } from 'react';

import './style.css';

// Компонент для загрузки файлов

function FileInput({ size, required = false, maxFiles = 1, allowedTypes = ['image/jpeg', 'image/jpg'], setFileList}) {
  const [fileError, setFileError] = useState('');
  const [files, setFiles] = useState([]);
  const [fileInputText, setFileInputText] = useState('Перетащите или Загрузите изображение');

  const handleFileChange = (event) => {
    const newFiles = Array.from(event.target.files);
  
    if (files.length + newFiles.length > maxFiles) {
      setFileError(`Максимальное количество файлов - ${maxFiles}.`);
      return;
    }
  
    const file = newFiles[0];
  
    if (!allowedTypes.includes(file.type)) {
      setFileError(`Разрешены только файлы с расширением .jpeg или .jpg.`);
      return;
    } else if (file.size > 5 * 1024 * 1024) {
      setFileError('Размер файла не должен превышать 5 МБ.');
      return;
    }
  
    setFiles(prevFiles => [...prevFiles, file]);
    setFileList(prevFiles => [...prevFiles, file])
    setFileError('');
  };
  

  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;

      if (screenWidth < 1200 && screenWidth > 0) {
        setFileInputText('Загрузите изображение');
      } else {
        setFileInputText('Перетащите или Загрузите изображение');
      }
    }

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  return (
    <div
      className={`file_input_wrapper ${size}_container
        ${fileError ? 'small_margin' : ''}
        ${fileError && files.length > 0 ? 'large_margin' : ''}`}
    >
      <input type='file' onChange={handleFileChange} className={`image_input ${size}_input`} required={required} />
      <div className={`file_input_content ${size}_content`}>
        <img className={`${size}_img`} src='/UserProfileSettingsAssets/file_input.svg' alt='input' />
        <div className={`file_input_text_container ${size}_text_container`}>
          <p className={`file_input_text ${size}_text`}>{fileInputText}</p>
          <p className={`file_input_description ${size}_text`}>размер до 5МБ, формат .jpg, .jpeg</p>
        </div>
      </div>
      {fileError && <p className={`file_error ${size}_error`}>{fileError}</p>}
       {files.length > 0 && (
        <div className='uploaded_files'>
          <ul>
            {files.map((file, index) => (
              <div key={index} className='files_container'>
              <a className='files_container' href={file.img} download>
                <img className='file_img' src='/FileInputAssets/file_img.png' alt={`File ${index}`} />
                <div>
                  <h6 className='file_title'>{file.name}</h6>
                  <p className='file_size'>{(file.size / (1024 * 1024)).toFixed(2)} МБ</p> 
                </div>
              </a>
            </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
  
  
  
}

export default FileInput;
