import React from 'react';

import './style.css';

// Компонент, отображающий список видео проектов 

function ProjectVideos({ projectVideos }) {

  if (!projectVideos || projectVideos.length === 0) {
    return null;
  }

  return (
    <div className="video_container">
      {projectVideos.map((videoUrl, index) => {
        const videoId = videoUrl.split('v=')[1];
        return (
          <div key={index} className='project_video'>
            <iframe
              className='project_video'
              title={`Project video ${index + 1}`}
              width='150'
              height='75'
              src={`https://www.youtube.com/embed/${videoId}`}
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            ></iframe>
          </div>
        );
      })}
    </div>
  );
}

export default ProjectVideos;
