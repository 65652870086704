import EducationalSlider from '../EducationalSlider';
import './style.css';
import { href } from '../../pages/href.js'
import { useState } from 'react';


function EducationalInfo ({donatsCount, donatsSum, projectName}) {
  const [sliderValue, setSliderValue] = useState(0);

  const sendMark = () => {
    const fetchData = async (event) => {
      const settingsData = {
        jwt: localStorage.jwt,
        sliderValue: sliderValue,
        projectName: projectName
      };

      try {
        const response = await fetch(href + 'project_value', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(settingsData),
        });

        const result = await response.json();
        if (!response.ok) {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }
    fetchData();
  };

  return (
    <>
      <div className='donation_content_wrapper'>
        <div className='donat_info_container'>
          <div className='donats_sum_container_left'>
            <p className='donat_text'>{donatsCount}</p>
            <p className='donat_description'>Количество донатов</p>
          </div>
          <div className='donats_sum_container'>
            <p className='donat_text'>{donatsSum}</p>
            <p className='donat_description'>Сумма донатов</p>
          </div>
        </div>
        <div className="polzunok">
            <EducationalSlider projectName={projectName} setSlider={setSliderValue}></EducationalSlider>
        </div>
        <button className='donat_button' onClick={sendMark}>Оценить</button>
      </div>
    </>
  );
}

export default EducationalInfo;
