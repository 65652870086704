import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../components/Header';
import Select from '../../components/Select';
import Loader from '../../components/Loader';
import UserProfileCapInput from '../../components/UserProfileCapInput';
import UserProfileImageInput from '../../components/UserProfileImageInput';
import CategoryPanel from '../../components/CategoryPanel';
import useWindowWidthPanel from '../../components/CategoryPanel/useWindowWidth';
import useWindowWidth from '../../hooks/useWindowWidth';
import useLockBodyScroll from '../../hooks/useBodyLock';

import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useTheme } from '../../components/ThemeContex';
import { useUserLogged } from '../Home/useUserLoggedState';
import { href } from '../href.js'

import './style.css';

import {
  languageTitle,
  languageOptions,
  themeOptions,
  themeTitle,
  countryOptions,
  countryTitle
} from './constants';

function UserProfileSettingsPage() {
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('')
  const [link, setLink] = useState('')
  const [description, setDescription] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const { theme, toggleTheme } = useTheme();
  const [selectedTheme, setSelectedTheme] = useState(theme);
  const windwodWidth = useWindowWidth()
  const visible = useWindowWidthPanel()
  const [icon, setIcon] = useState('')
  const [cap, setCap] = useState('')
  const [ProfileIcon, setProfileIcon] = useState('');
  const [ProfileCap, setProfileCap] = useState('');

  useLockBodyScroll(showCategoryPanel)

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch(href + 'user_settings', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setName(result.name)
              setLink(result.link)
              setDescription(result.description)
              setSelectedLanguage(result.language)
              setSelectedTheme(result.theme)
              setSelectedCountry(result.country)
              setProfileIcon(result.path_profile_icon)
              setIcon(result.path_profile_icon)
              setProfileCap(result.path_profile_cap)
              setCap(result.path_profile_cap)
              localStorage.setItem('theme', result.theme);
              toggleTheme(result.theme)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      } else{
        navigate('/')
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('jwt', localStorage.jwt)
    formData.append('name', name);
    formData.append('link', link);
    formData.append('description', description);
    formData.append('language', selectedLanguage);
    formData.append('theme', selectedTheme);
    formData.append('country', selectedCountry);
    if (ProfileIcon != icon){
      formData.append('profile_icon', ProfileIcon);
    }
    if (ProfileCap != cap){
      formData.append('profile_cap', ProfileCap);
    }

    try {
      const response = await fetch(href + 'set_user_settings', {
        method: 'POST',
        body: formData,
      });

      const result = await response.json();
      if (response.ok) {
        setIsLoading(false);
        if (result.msg){
          setNameError('Такой пользователь уже существует')
        }
      } else {
        throw new Error(result.msg);
      }
    } catch (error) {
      console.error('Ошибка:', error);
      setIsLoading(false);
    }
  };

  const handleThemeChange = (selectedValue) => {
    setSelectedTheme(selectedValue);
    toggleTheme(selectedValue);
  };

  const handleNameChange = (name) => {
    setName(name)
    setNameError('')
  }

  return (
    <div className='user_profile_settings_container'>
      <Header isUserLogged={isUserLogged} isLarge={true} toggleCategoryPanel={toggleCategoryPanel} setIsUserLogged={setIsUserLogged} ProfileIcon={ProfileIcon}/>
      <CategoryPanel visible={visible} showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} />
      {showCategoryPanel && <div className='dark_overlay'></div>}
      {isLoading ? (
        <Loader loaderText='Сохраняем изменения' />
      ) : (
        <form className='user_profile_content' onSubmit={handleSubmit}>
          <h2 className='user_profile_title'>Профиль пользователя</h2>
          <div className='user_profile_container'>
            <div className='user_profile_left_container'>
              <p className='user_prolife_description'>Фото профиля</p>
              <UserProfileImageInput setProfileIcon={setProfileIcon} ProfileIcon={ProfileIcon}/>
              {windwodWidth <= 743 && 
                <>
                <p className='user_prolife_description'>Шапка профиля</p>
                <UserProfileCapInput setProfileCap={setProfileCap} ProfileCap={ProfileCap} size='default' />
                </>
              }
              <p className='user_prolife_description'>Имя/псевдоним</p>
              <input
                className='user_input'
                placeholder='Введите'
                value={name}
                maxLength={128}
                onChange={(e) => handleNameChange(e.target.value)}
              />
              {nameError && <p className='error_message setting'>{nameError}</p> }
               {windwodWidth <= 743 && 
                <>
                  <p className='user_prolife_description'>Описание</p>
                  <input
                    className='description_input'
                    placeholder='Напишите'
                    value={description}
                    maxLength={2048}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                  <p className='user_prolife_description'>Ссылка на социальные сети</p>
                  <input
                    className='user_input'
                    placeholder='Например, https://instagram.com'
                    value={link}
                    maxLength={128}
                    onChange={(e) => setLink(e.target.value)}
                  />
                </>
              }
              <Select
                options={languageOptions}
                title={languageTitle}
                setValue={setSelectedLanguage}
                val={selectedLanguage}
              />
                {windwodWidth <= 743 && 
                <>
                <Select
                  options={languageOptions}
                  title={languageTitle}
                  setValue={setSelectedLanguage}
                  val={selectedLanguage}
                />
                </>
              }
              <Select
                options={themeOptions}
                title={themeTitle}
                setValue={handleThemeChange}
                val={selectedTheme}
              />
              <button type='submit' className='save_button'>Сохранить</button>
            </div>
            {windwodWidth >= 744 && <div>
              <p className='user_prolife_description'>Шапка профиля</p>
              <div>
                <UserProfileCapInput setProfileCap={setProfileCap} ProfileCap={ProfileCap} size='default' />
                <p className='user_prolife_description'>Описание</p>
                <input
                  className='description_input'
                  placeholder='Напишите'
                  value={description}
                  maxLength={2048}
                  onChange={(e) => setDescription(e.target.value)}
                />
                <Select
                  options={countryOptions}
                  title={countryTitle}
                  setValue={setSelectedCountry}
                  val={selectedCountry}
                />
              </div>
              <div className='user_profile_link_container'>
                <p className='user_prolife_description'>Ссылка на социальные сети</p>
                <input
                  className='user_input'
                  placeholder='Например, https://instagram.com'
                  value={link}
                  maxLength={128}
                  onChange={(e) => setLink(e.target.value)}
                />
              </div>
            </div>}
          </div>
        </form>
      )}
    </div>
  );
}

export default UserProfileSettingsPage;
