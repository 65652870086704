// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-backdrop-filter: blur(8px);
          backdrop-filter: blur(8px);
  z-index: 999;
}

.modal_wrapper {
  background-color: var(--bg-color);
  padding: 20px;
  border-radius: 8px;
  width: 1000px;
  height: 600px;
  text-align: center;
}

@media (min-width: 744px) and (max-width: 1199px) {
  .modal_wrapper {
    width: 704px;
    height: 700px;
    margin-left: 20px;
    margin-right: 20px;
  }   
}

@media (min-width: 0px) and (max-width: 743px) {
  .modal_wrapper {
    width: 320px;
    height: 510px;
    margin-left: 20px;
    margin-right: 20px;
  }   
}`, "",{"version":3,"sources":["webpack://./src/components/ModalContentContainer/style.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,kCAA0B;UAA1B,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,iCAAiC;EACjC,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,YAAY;IACZ,aAAa;IACb,iBAAiB;IACjB,kBAAkB;EACpB;AACF","sourcesContent":[".modal {\r\n  position: fixed;\r\n  top: 0;\r\n  left: 0;\r\n  width: 100%;\r\n  height: 100%;\r\n  background-color: rgba(0, 0, 0, 0.5);\r\n  display: flex;\r\n  justify-content: center;\r\n  align-items: center;\r\n  backdrop-filter: blur(8px);\r\n  z-index: 999;\r\n}\r\n\r\n.modal_wrapper {\r\n  background-color: var(--bg-color);\r\n  padding: 20px;\r\n  border-radius: 8px;\r\n  width: 1000px;\r\n  height: 600px;\r\n  text-align: center;\r\n}\r\n\r\n@media (min-width: 744px) and (max-width: 1199px) {\r\n  .modal_wrapper {\r\n    width: 704px;\r\n    height: 700px;\r\n    margin-left: 20px;\r\n    margin-right: 20px;\r\n  }   \r\n}\r\n\r\n@media (min-width: 0px) and (max-width: 743px) {\r\n  .modal_wrapper {\r\n    width: 320px;\r\n    height: 510px;\r\n    margin-left: 20px;\r\n    margin-right: 20px;\r\n  }   \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
