import React, { useEffect, useState } from 'react';

import './style.css';

// Комопонент, который отображает секцию в CreateProject

function ExpandableSection({ children, title, description, isEducational }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    if (isEducational) {
      setIsExpanded(false);
    }
  }, [isEducational]);

  return (
    <div className={`expandable_section_wrapper ${isExpanded ? 'expanded' : ''} ${isEducational ? 'educational' : ''}`}>
      <div className={`expandable_section_header ${isEducational ? 'educational' : ''}`} onClick={!isEducational ? toggleExpand : null}>
        {!isEducational ? <span className='expanded_section_img section_button'></span> : <span className='expanded_section_img section_error'></span>}
        <div className='expandable_section_container'>
          <h3 className={`expandable_section_title ${isEducational ? 'educational' : ''}`}>{title}</h3>
          <p className="expandable_section_description"> {!isEducational ? description : 'В учебном проекте нельзя добавить донаты'}</p>
        </div>
        <span className={`arrow_btn ${isExpanded ? 'arrow_up' : 'arrow_down'}`}></span>
      </div>
      <div className={`expandable_section_content ${isExpanded ? 'expanded' : ''}`}>{children}</div>
    </div>
  );
}

export default ExpandableSection;
