// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification_container {
  position: relative;
}

.notification_container .notification_list {
  position: absolute;
  top: 130%; 
  right: 0;
  max-height: 400px;
  overflow-y: auto;
  background-color: var(--notification-color);
  display: block;
  z-index: 999;
  width: 290px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.18);
  border-radius: 12px;
}


@media (min-width: 0px) and (max-width: 743px) {
  .notification_list {
    right: -65px !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/NotificationContainer/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;EACR,iBAAiB;EACjB,gBAAgB;EAChB,2CAA2C;EAC3C,cAAc;EACd,YAAY;EACZ,YAAY;EACZ,gDAAgD;EAChD,mBAAmB;AACrB;;;AAGA;EACE;IACE,uBAAuB;EACzB;AACF","sourcesContent":[".notification_container {\r\n  position: relative;\r\n}\r\n\r\n.notification_container .notification_list {\r\n  position: absolute;\r\n  top: 130%; \r\n  right: 0;\r\n  max-height: 400px;\r\n  overflow-y: auto;\r\n  background-color: var(--notification-color);\r\n  display: block;\r\n  z-index: 999;\r\n  width: 290px;\r\n  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.18);\r\n  border-radius: 12px;\r\n}\r\n\r\n\r\n@media (min-width: 0px) and (max-width: 743px) {\r\n  .notification_list {\r\n    right: -65px !important;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
