import React, { useState, useEffect } from 'react';
import CategoryPanel from '../../components/CategoryPanel';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import useWindowWidth from '../../hooks/useWindowWidth';
import useLockBodyScroll from '../../hooks/useBodyLock';
import { useNavigate } from 'react-router-dom';

import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useUserLogged } from '../Home/useUserLoggedState';
import { ReactComponent as Hands} from '../../components/ProjectPageDesktop/assets/hands.svg'
import { Link } from 'react-router-dom';
import { href } from '../href.js'

import './style.css';

function BestProjectsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [projects, setProjects] = useState([]);
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const screenWidth = useWindowWidth()
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const navigate = useNavigate();
  const [ProfileIcon, setProfileIcon] = useState('');

  useLockBodyScroll(showCategoryPanel)

  const isTabletScreen = screenWidth > 0 && screenWidth < 1200;

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch(href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }

    try{
      const fetchData = async (event) => {
        const settingsData = {
          jwt: localStorage.jwt
        };

        try {
          const response = await fetch(href + 'get_best_projects', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(settingsData),
          });

          const result = await response.json();
          if (response.ok) {
            setProjects(result.bestProjecets);
            setIsLoading(false);
          } else {
            throw new Error(result.msg);
          }
        } catch (error) {
          console.error('Ошибка:', error);
          setIsLoading(false);
        }
      }
      fetchData();
  } catch (error) {
    console.error('Ошибка:', error);
  }
  },[])

  return (
    <div className='best_projects_container'>
      <CategoryPanel showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} visible={true} />
      <div className='best_project_wrapper'>
        {showCategoryPanel && <div className='dark_overlay'></div>}
        <Header isUserLogged={isUserLogged} isLarge={false} toggleCategoryPanel={toggleCategoryPanel} setIsUserLogged={setIsUserLogged} ProfileIcon={ProfileIcon}/>
        {isLoading ? (
          <Loader loaderText='Загружаем проекты' />
        ) : (
          <div className='best_projects_content'>
            {projects.map((project, categoryIndex) => (
              <div key={categoryIndex}>
                <p className='best_project_category'>{project.category}</p>
                <div className='projects_container'>
                  <div className='large_card_container'>
                    <Link to={`/projects/${project.projects[0]?.title}`} className='best_project_link'>
                      <div className='project_card large_card'>
                        <img src={project.projects[0].projectImg} alt={project.projects[0].title} className='big_project_img' />
                        <div className='project_card_tablet'>
                          {isTabletScreen && (
                            <h3 className='best_project_title'>{project.projects[0].title}</h3>
                          )}
                           {isTabletScreen && (
                            <p className='project_description'>{project.projects[0].description}</p>
                          )}
                          <div className='project_info'>
                            <img className='best_project_author_img' src={project.projects[0].authorImg} alt='author img' />
                            {project.projects[0].author && <p className='best_project_author'>{project.projects[0].author}</p>}
                            <span className='dot'></span>
                            <div className='hands' >
                              <Hands />
                            </div>
                            <p className='best_project_likes'>{project.projects[0].likes} оценили</p>
                          </div>
                          {screenWidth >= 1200 && (
                            <h3 className='best_project_title'>{project.projects[0].title}</h3>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className='small_cards_container'>
                    {project.projects.slice(1).map((proj, index) => (
                      <Link to={`/projects/${proj?.title}`} className='best_project_link' key={index}>
                        <div className='project_card small_card'>
                          <img className='small_project_img' src={proj.projectImg} alt={proj.title} />
                          <p className='small_project_title'>{proj.title}</p>
                        </div>
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default BestProjectsPage;
