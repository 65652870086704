import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { routes } from './routes';
import { CategoryPanelProvider } from '../CategoryPanelContext';
import { UserLoggedProvider } from '../../pages/Home/useUserLoggedState';
import { ThemeProvider } from '../ThemeContex';
import { ErrorComponent, ErrorProvider } from '../ErrorProvider';

// Компонент, который отвечает за роутинг

function App() {
  return (
    <BrowserRouter>
    <ThemeProvider>
      <ErrorProvider>
      <CategoryPanelProvider>
        <UserLoggedProvider>
        <Routes>
          {routes.map((route, index) => (
            <Route key={index} path={route.path} element={route.element} />
          ))}
        </Routes>
        <ErrorComponent />
        </UserLoggedProvider>
      </CategoryPanelProvider>
      </ErrorProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;