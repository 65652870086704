// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/NotificationItemAssets/notification.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification {
  width: auto;
  height: 40px;
  margin-top: 17px;
  margin-bottom: 17px;
  display: flex;
  align-items: center;
}

.notification_img {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  width: 40px;
  height: 40px;
  background-color: rgba(81, 189, 71, 0.2);
  background-repeat: no-repeat;
  border-radius: 8px;
  background-position: 8px 8px;
  margin-left: 15px;
}

.notification_text_container {
  margin-right: 10px;
  width: 200px;
  margin-left: 6px;
}

.notification_text {
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
  color: var(--font-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 4px;
}

.notification_description {
  font-family: var(--main-font);
  font-size: 13px;
  font-weight: 500;
  color: var(--secondary-text-color);
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/NotificationItem/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,yDAA8E;EAC9E,WAAW;EACX,YAAY;EACZ,wCAAwC;EACxC,4BAA4B;EAC5B,kBAAkB;EAClB,4BAA4B;EAC5B,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,wBAAwB;EACxB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,kCAAkC;EAClC,0BAA0B;EAC1B,eAAe;AACjB","sourcesContent":[".notification {\r\n  width: auto;\r\n  height: 40px;\r\n  margin-top: 17px;\r\n  margin-bottom: 17px;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.notification_img {\r\n  background-image: url(../../../public/NotificationItemAssets/notification.svg);\r\n  width: 40px;\r\n  height: 40px;\r\n  background-color: rgba(81, 189, 71, 0.2);\r\n  background-repeat: no-repeat;\r\n  border-radius: 8px;\r\n  background-position: 8px 8px;\r\n  margin-left: 15px;\r\n}\r\n\r\n.notification_text_container {\r\n  margin-right: 10px;\r\n  width: 200px;\r\n  margin-left: 6px;\r\n}\r\n\r\n.notification_text {\r\n  font-family: var(--main-font);\r\n  font-size: 13px;\r\n  font-weight: 500;\r\n  color: var(--font-color);\r\n  white-space: nowrap;\r\n  overflow: hidden;\r\n  text-overflow: ellipsis;\r\n  margin-bottom: 4px;\r\n}\r\n\r\n.notification_description {\r\n  font-family: var(--main-font);\r\n  font-size: 13px;\r\n  font-weight: 500;\r\n  color: var(--secondary-text-color);\r\n  text-decoration: underline;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
