// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video_container {
  gap: 10px;
  display: flex;
  overflow-x: auto;
  width: 499px;
  margin-bottom: 10px;
  height: 110px;
}

.project_video {
  min-width: 159px;
  height: 85px;
  background-repeat: no-repeat;
  background-position: 65px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
}

@media (min-width: 744px) and (max-width: 1199px) {
  .video_container {
    margin-left: 20px;
    margin-top: 38px;
    width: 704px;
    justify-content: space-between;
  }
}

@media (min-width: 0px) and (max-width: 743px) {
  .video_container {
    width: 320px;
    gap: 20px;
    margin-top: 30px;
    height: 100px;
  }

  .project_video {
    min-width: 150px;
    height: 80px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectVideos/style.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,aAAa;EACb,gBAAgB;EAChB,YAAY;EACZ,mBAAmB;EACnB,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,4BAA4B;EAC5B,8BAA8B;EAC9B,YAAY;EACZ,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,YAAY;IACZ,8BAA8B;EAChC;AACF;;AAEA;EACE;IACE,YAAY;IACZ,SAAS;IACT,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,gBAAgB;IAChB,YAAY;EACd;AACF","sourcesContent":[".video_container {\r\n  gap: 10px;\r\n  display: flex;\r\n  overflow-x: auto;\r\n  width: 499px;\r\n  margin-bottom: 10px;\r\n  height: 110px;\r\n}\r\n\r\n.project_video {\r\n  min-width: 159px;\r\n  height: 85px;\r\n  background-repeat: no-repeat;\r\n  background-position: 65px 30px;\r\n  border: none;\r\n  border-radius: 8px;\r\n  cursor: pointer;\r\n}\r\n\r\n@media (min-width: 744px) and (max-width: 1199px) {\r\n  .video_container {\r\n    margin-left: 20px;\r\n    margin-top: 38px;\r\n    width: 704px;\r\n    justify-content: space-between;\r\n  }\r\n}\r\n\r\n@media (min-width: 0px) and (max-width: 743px) {\r\n  .video_container {\r\n    width: 320px;\r\n    gap: 20px;\r\n    margin-top: 30px;\r\n    height: 100px;\r\n  }\r\n\r\n  .project_video {\r\n    min-width: 150px;\r\n    height: 80px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
