// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_container {
  margin: auto;
  display: block;
  align-items: center;
  text-align: center;
}

.loader_text {
  font-family: var(--main-font);
  font-size: 24px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--font-color);
}

.loader_img {
  margin: auto;
  width: 35px;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  animation: spin 1.5s infinite linear;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Loader/style.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;EAClB,wBAAwB;AAC1B;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,mBAAmB;EACnB,oCAAoC;AACtC;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loader_container {\r\n  margin: auto;\r\n  display: block;\r\n  align-items: center;\r\n  text-align: center;\r\n}\r\n\r\n.loader_text {\r\n  font-family: var(--main-font);\r\n  font-size: 24px;\r\n  font-weight: 600;\r\n  line-height: 38px;\r\n  letter-spacing: 0em;\r\n  text-align: center;\r\n  color: var(--font-color);\r\n}\r\n\r\n.loader_img {\r\n  margin: auto;\r\n  width: 35px;\r\n  height: 35px;\r\n  margin-top: 10px;\r\n  margin-bottom: 10px;\r\n  animation: spin 1.5s infinite linear;\r\n}\r\n\r\n@keyframes spin {\r\n  to {\r\n    transform: rotate(360deg);\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
