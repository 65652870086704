// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.category {
  display: flex;
  align-items: center;
  width: 210px;
  margin-bottom: 10px;
  cursor: pointer;
}

.category_icon {
  width: 20px;
  height: 20px;
  background-color: var(--category-icon-bg-color);
  border-radius: 6px;
  padding: 5px;
  object-fit: cover;
}

.hovered_icon {
  background-color: var(--category-panel-btn-hover-color);
}

.category_name {
  font-family: var(--main-font);
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  margin-left: 10px;
  color: var(--font-color);
}

.selected {
  background-color: var(--selected-category-color);
  border-radius: 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/CategoryItem/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,mBAAmB;EACnB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,+CAA+C;EAC/C,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,uDAAuD;AACzD;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,gDAAgD;EAChD,kBAAkB;AACpB","sourcesContent":[".category {\r\n  display: flex;\r\n  align-items: center;\r\n  width: 210px;\r\n  margin-bottom: 10px;\r\n  cursor: pointer;\r\n}\r\n\r\n.category_icon {\r\n  width: 20px;\r\n  height: 20px;\r\n  background-color: var(--category-icon-bg-color);\r\n  border-radius: 6px;\r\n  padding: 5px;\r\n  object-fit: cover;\r\n}\r\n\r\n.hovered_icon {\r\n  background-color: var(--category-panel-btn-hover-color);\r\n}\r\n\r\n.category_name {\r\n  font-family: var(--main-font);\r\n  font-size: 14px;\r\n  font-weight: 500;\r\n  line-height: 16px;\r\n  margin-left: 10px;\r\n  color: var(--font-color);\r\n}\r\n\r\n.selected {\r\n  background-color: var(--selected-category-color);\r\n  border-radius: 8px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
