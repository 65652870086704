import './style.css'

import { ReactComponent as LoaderImg } from './assets/loader.svg'

// Компонент, отвечающий за отображение лоадера

function Loader ({loaderText}) {
  return (
    <div className='loader_container'>
      <LoaderImg  className='loader_img'/>
      <p className="loader_text">{loaderText}</p>
    </div>
  )
}

export default Loader