import React from 'react';

import './style.css'

// Компонент инпута пароля

function PasswordInput({ password, setPassword, passwordError, setPasswordError }) {

  const handlePasswordChange = (event) => {
    const newPassword = event.target.value;
    setPassword(newPassword);

    const hasLetter = /[a-zA-Z]/.test(newPassword);
    const hasDigit = /[0-9]/.test(newPassword);
    const isValidLength = newPassword.length >= 9;

    if (!hasLetter || !hasDigit || !isValidLength) {
      setPasswordError('Пароль должен содержать минимум 9 знаков, 1 букву и 1 символ.');
    } else {
      setPasswordError('');
    }
  };

  return (
    <div className='registration_input_container'>
      <h6 className='registration_input_title'>Пароль</h6>
      <div className='password-input-container'>
        <input
          className='registration_input'
          type={'password'}
          id='password'
          name='password'
          value={password}
          onChange={handlePasswordChange}
          placeholder='Введите пароль'
          required
        />
      </div>
      {passwordError && <p className='error_message'>{passwordError}</p>}
    </div>
  );
}

export default PasswordInput;
