import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader';

import './style.css';

// Компонент, который отображает список донатеров

function DonatersList({ donaters }) {
  const [expanded, setExpanded] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(5);
  const [loading, setLoading] = useState(false);
  const containerRef = useRef(null);
  const navigator = useNavigate();

  const toggleExpanded = () => {
    setExpanded(!expanded);
    if (!expanded) {
      setLoading(true);
      setTimeout(() => {
        setItemsToShow(prev => prev + 10);
        setLoading(false);
      }, 500);
    } else {
      setItemsToShow(5);
    }
  };

  const goToUserProfile = (userName) => {
    navigator(`/user_profile?query=${userName}`);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1199 && window.innerWidth >= 744) {
        setItemsToShow(itemsToShow > 5 ? donaters.length : 4);
      } else {
        setItemsToShow(itemsToShow > 5 ? donaters.length : 5);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (!loading && container.scrollTop + container.clientHeight >= container.scrollHeight - 100 && donaters.length > itemsToShow && expanded) {
        setLoading(true);
        setTimeout(() => {
          setItemsToShow(prev => Math.min(prev + 5, donaters.length));
          setLoading(false);
        }, 500);
      }
    };

    const container = containerRef.current;
    container.addEventListener('scroll', handleScroll);

    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [itemsToShow, loading]);

  return (
    <div className='donaters_list_container' ref={containerRef}>
      {donaters.slice(0, itemsToShow).map((donater, index) => (
        <div onClick={() => goToUserProfile(donater.userName)}
          className={`donater_item_container ${window.innerWidth >= 744 && window.innerWidth <= 1200 ? 'with_border' : ''}
            ${index === itemsToShow - 1 ? '' : 'with_border'}`}
          key={index}
        >
          <img className='donater_img' src={donater.userImg} alt={donater.userName} />
          <p className='donater_name'>{donater.userName}</p>
          <p className='donater_donation_sum'>{donater.donationSum}</p>
        </div>
      ))}
      {loading && (
        <div className='loading_indicator'>
          <span className='loader'></span>
          <Loader loaderText={'Загрузка'}></Loader>
        </div>
      )}
      {!expanded && donaters.length > itemsToShow && (
        <button className='show_donaters_button' onClick={toggleExpanded}>
          Развернуть
          <span className={`list_arrow down`}></span>
        </button>
      )}
      {expanded && donaters.length === itemsToShow && (
        <button className='show_donaters_button' onClick={toggleExpanded}>
          Свернуть
          <span className={`list_arrow up`}></span>
        </button>
      )}
    </div>
  );
}

export default DonatersList;

