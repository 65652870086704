import React from 'react';

const currentNubmer = '23232323232'; // Телефон при вводе которого будет плашка ошибка

// Компонент для ввода номера телефона

function PhoneNumberInput({ phoneNumber, setPhoneNumber, phoneNumberError, setPhoneNumberError }) {
  const handlePhoneNumberChange = (event) => {
    let inputNumber = event.target.value;
  
    // Removing all non-digit characters
    inputNumber = inputNumber.replace(/\D/g, '');
  
    // If more than 11 characters are entered, remove the rest
    if (inputNumber.length > 11) {
      inputNumber = inputNumber.slice(0, 11);
    }
  
    let formattedNumber = '+';
    if (inputNumber.length > 0) {
      formattedNumber += inputNumber.substring(0, 1);
    }
    if (inputNumber.length > 1) {
      formattedNumber += ` (${inputNumber.substring(1, 4)})`;
    }
    if (inputNumber.length > 4) {
      formattedNumber += ` ${inputNumber.substring(4, 7)}`;
    }
    if (inputNumber.length > 7) {
      formattedNumber += ` ${inputNumber.substring(7, 9)}`;
    }
    if (inputNumber.length > 9) {
      formattedNumber += ` ${inputNumber.substring(9)}`;
    }
  
    if (inputNumber.length !== 11) {
      setPhoneNumberError('Введите номер полностью');
    } else {
      if (inputNumber === currentNubmer) {
        setPhoneNumberError('Данный номер занят');
      } else {
        setPhoneNumberError('');
      }
    }

    // Checking for six or more consecutive identical digits in the number
    if (/(.)\1{9,}/.test(inputNumber.replace(/\D/g, ''))) {
      setPhoneNumberError('Номер не может содержать девять или более одинаковых цифр подряд');
    }
  
    setPhoneNumber(formattedNumber);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Backspace' && event.target.selectionStart === event.target.selectionEnd) {
      const caretPos = event.target.selectionStart;
      if (phoneNumber[caretPos - 1] === ')' || phoneNumber[caretPos - 1] === ' ') {
        setPhoneNumber(phoneNumber.slice(0, caretPos - 1) + phoneNumber.slice(caretPos));
      }
    }
  };

  return (
    <div className='registration_input_container'>
      <h6 className='registration_input_title'>Номер телефона</h6>
      <input
        className='registration_input'
        type='tel'
        id='phoneNumber'
        name='phoneNumber'
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        onKeyDown={handleKeyDown}
        placeholder='+7 (___) ___ __ __'
        required
      />
      {phoneNumberError && <p className='error_message'>{phoneNumberError}</p>}
    </div>
  );
}

export default PhoneNumberInput;
