export const mockProjects = [
  {
    title: 'Grand Theft Auto 6',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['google.com', 'Ссылка на код??'],
    projectImg: '/BestProjectsAssets/big_background.png',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png', '/ProjectPageAssets/ProjectImg2.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 11267,
  },
  {
    title: 'Google Chrome',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsAssets/small_background1.png',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png', '/ProjectPageAssets/ProjectImg2.png' , '/ProjectPageAssets/ProjectImg4.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsAssets/small_background2.png',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png', '/ProjectPageAssets/ProjectImg2.png' , '/ProjectPageAssets/ProjectImg4.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Grand Theft Auto 5',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['Ссылка на источник', 'Ссылка на код??'],
    projectImg: '/BestProjectsAssets/small_background3.png',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png',],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc',],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Google Chrome 2',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsAssets/small_background4.png',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png',],
    projectVideos: [],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png', '/ProjectPageAssets/ProjectImg2.png' , '/ProjectPageAssets/ProjectImg4.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },  {
    title: 'Grand Theft Auto 6',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['Ссылка на источник', 'Ссылка на код??'],
    projectImg: '/BestProjectsImages/big_background.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg3.png', '/ProjectPageAssets/ProjectImg2.png' , '/ProjectPageAssets/ProjectImg4.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Google Chrome',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsImages/small_background1.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },  {
    title: 'Grand Theft Auto 6',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['Ссылка на источник', 'Ссылка на код??'],
    projectImg: '/BestProjectsImages/big_background.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Google Chrome',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsImages/small_background1.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },  {
    title: 'Grand Theft Auto 6',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['Ссылка на источник', 'Ссылка на код??'],
    projectImg: '/BestProjectsImages/big_background.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Google Chrome',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsImages/small_background1.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },  {
    title: 'Grand Theft Auto 6',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 309,
    tags: ['ВИДЕОИГРЫ', 'GTA'],
    links: ['Ссылка на источник', 'Ссылка на код??'],
    projectImg: '/BestProjectsImages/big_background.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.72 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'Document GTA.pdf',
          size: '1.42 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: 'Google Chrome',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 321,
    tags: ['ПО', 'CHROME'],
    links: ['Ссылка на источник', 'Ссылка на код'],
    projectImg: '/BestProjectsImages/small_background1.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'GTA 6 presentation.pdf',
      size: '6.12 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Документация',
      files: [
        {
          title: 'DocumentChrome.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
  
  {
    title: '3D Architecture',
    description: 'Lorem ipsum dolor sit amet consectetur. Rhoncus lectus habitant arcu rutrum sed.',
    img: '/ProjectItemAssets/project_img.png',
    author: 'Petr_Byrsov',
    authorImg: '/BestProjectsAssets/authorImg.svg',
    likes: 157,
    tags: ['ИНЖЕНЕРНЫЙ ПРОЕКТ', '3D'],
    links: ['Ссылка на источник'],
    projectImg: '/BestProjectsImages/small_background2.svg',
    projectImages: ['/ProjectPageAssets/ProjectImg1.png', '/ProjectPageAssets/ProjectImg5.png'],
    projectVideos: ['https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc', 'https://www.youtube.com/watch?v=Ya6UF1MiaTc'],
    longDescription: 'Lorem ipsum dolor sit amet consectetur. Facilisi diam risus fames aliquam elementum sed. Pretium eleifend hendrerit fringilla massa fermentum eleifend urna.',
    goalText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    donatText: 'faucibus tempus nibh a tincidunt habitant. Commodo aliquam velit enim erat orci tellus semper sodales neque.',
    presentation: {
      title: 'Architecture3d.pdf',
      size: '3.22 MB',
      img: '/ProjectPageAssets/presentation.png'
    },
    anotherFiles: {
      title: 'Технические чертежи и CAD-файлы',
      files: [
        {
          title: 'Doc12.pdf',
          size: '1.45 MB',
          img: '/ProjectPageAssets/doc.png'
        },
        {
          title: 'Doc12_1.pdf',
          size: '1.87 MB',
          img: '/ProjectPageAssets/doc.png'
        },
      ]
    },
    donatsCount: 343,
    donatsSum: '13 567 ₽',
    startGoal : 0,
    finalGoal: 15000,
    currentValue: 13567,
  },
];