import React, { useState, useEffect } from 'react';
import Registartion from '../../components/Registration';
import CodeInput from '../../components/CodeInput';
import Login from '../../components/Login';
import TelegramLogin from '../../components/TelegramLogin';

import { useNavigate } from 'react-router-dom';
import { useUserLogged } from '../Home/useUserLoggedState';

import './style.css';

function RegistrationPage() {
    const [registrationState, setRegistrationState] = useState('login'); // Possible states: 'registration', 'codeInput', 'login
    const { setIsUserLogged } = useUserLogged();
    const navigate = useNavigate()
    const [phoneNumber, setPhoneNumber] = useState('');

    const handleRegistrationButtonClick = () => {
        setRegistrationState('codeInput');
    };

    const handleCodeSubmit = () => {
        setRegistrationState('login');
    };

    const handleLogin = () => {
      try{
        if (localStorage.jwt !== ''){
          setIsUserLogged(true)
        }
      } catch (error) {
          console.error('Ошибка:', error);
      }
      navigate('/')
    };

    useEffect(()=> {
      try{
        if (localStorage.jwt !== ''){
          setIsUserLogged(true)
          navigate('/')
        }
      } catch (error) {
          console.error('Ошибка:', error);
      }
    },[])

    return (
        <>
          {registrationState === 'registration' && (
            <Registartion
              handleRegistrationButtonClick={handleRegistrationButtonClick}
              setRegistrationState={setRegistrationState}
              setNumber={setPhoneNumber}
            />
          )}
          {registrationState === 'codeInput' && (
            <CodeInput
              handleCodeSubmit={handleCodeSubmit}
              phoneNumber={phoneNumber}
            />
          )}
          {registrationState === 'login' && (
            <Login
              setRegistrationState={setRegistrationState}
              handleLogin={handleLogin}
            />
          )}
            <TelegramLogin />
        </>
    );
}

export default RegistrationPage;
