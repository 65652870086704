import { Link } from 'react-router-dom'
import { ReactComponent as Hands } from './assets/hands.svg'

import './style.css'

// Компонент, отображащий айтем проекта

function ProjectItem ({title, likes, img, authorImg, author}) {
  return (
    <Link to={`/projects/${title}`} className='project_link'>
      <div className='project_item_container'>
        <img src={img} alt='project_background' className='project_background_img'></img>
        <div className='project_item_description_wrapper'>
          <img className='project_item_author_img' src={authorImg} alt='author img'></img>
          <p className='project_item_author'>{author}</p>
          <p className='project_item_dot'></p>
          <div className='project_item_hands'>
            <Hands />
          </div>
          <p className='project_item_likes'>{likes}</p>
        </div>
        <h6 className='project_item_title'>{title}</h6>
      </div>
    </Link>
  )
}

export default ProjectItem
