import React, { useState, useEffect } from 'react';

import './style.css';
import { href } from '../../pages/href.js'

const DonationGoal = ({ setSlider, projectName }) => {
  const [sliderValue, setSliderValue] = useState();

  useEffect(() => {
    const fetchMark = async (event) => {
      const valueData = {
        jwt: localStorage.jwt,
        projectName: projectName
      };

      try {
        const response = await fetch(href + 'user_mark', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(valueData),
        });

        const result = await response.json();
        if (response.ok) {
          setSliderValue(result.mark)
        } else{
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }
    fetchMark();
  }, [])

  const handleSliderChange = (event) => {
    setSliderValue(event.target.value);
    setSlider(event.target.value)
  };

  return (
    <>
      <div className='donation_content_wrapper1'>
        <div className='donat_info_container1'>
          <div className='donats_sum_container_left1'>
            <input
                className="polzunok"
                type="range"
                min="0"
                max="10"
                step="1"
                value={sliderValue}
                onChange={handleSliderChange}
            />
          </div>
          <div className='donats_sum_container1'>
            <p className='donat_text1'>{sliderValue}</p>
            <p className='donat_description1'>Ваша отценка</p>
          </div>
        </div>
      </div>
    </>
  );
};



export default DonationGoal;
