import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { notifications } from '../../components/NotificationContainer/notifications';
import { href } from '../href.js'

// Общая логика между версиями вынесенная отдельно

function useProjectData() {
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [liked, setLiked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { title } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    
    const fetchData = async (event) => {
      const projectsData = {
        title: title
      };

      try {
        const response = await fetch(href + 'get_project', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectsData),
        });

        const result = await response.json();
        if (response.ok) {
          setProject(result.project)
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }

    fetchData();

    const timer = setTimeout(() => {
      setLoading(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [title]);

  const goToSearchPage = tag => {
    navigate(`/search?query=${tag}`);
  };

  const goToUserProfile = () => {
    navigate(`/user_profile?query=${project.author}`);
  };

  const goToLink = href => {
    window.open(href.startsWith('http://') || href.startsWith('https://') ? href : `http://${href}`, '_blank');
  };

  const handleLike = () => {
    setLiked(!liked);
  };

  const openModal = () => {
     const fetchDataComplaint = async (event) => {
      const projectData = {
        title: title,
        jwt: localStorage.jwt
      };

      try {
        const response = await fetch(href + 'complaint', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectData),
        });

        const result = await response.json();
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }

    fetchDataComplaint();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return { loading, project, liked, handleLike, isModalOpen, openModal, closeModal, goToSearchPage, goToLink, goToUserProfile };
}

export default useProjectData;
