import React, { useState, useEffect } from 'react';
import CategoryPanel from '../../components/CategoryPanel';
import Header from '../../components/Header';
import ProjectsList from '../../components/ProjectsList';
import Smile from './assets/smile.svg';
import useLockBodyScroll from '../../hooks/useBodyLock';

import { useNavigate } from 'react-router-dom';
import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useUserLogged } from './useUserLoggedState';
import { href } from '../href.js'

import './style.css';

const UnregisteredUserContent = ({ goToRegistration }) => (
  <div className='title_container'>
    <h2 className='main_title'>Исполни мечту</h2>
    <p className='title_description'>Создай проект, выложи его к нам и получи поддержку.</p>
    <button className='main_button' onClick={goToRegistration}>Начать</button>
  </div>
);

const HomePage = () => {
  const navigate = useNavigate();
  const { isUserLogged, setIsUserLogged } = useUserLogged();
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const [ProfileIcon, setProfileIcon] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [ Locate, setLocate ] = useState('')
  
  useLockBodyScroll(showCategoryPanel);

  const goToRegistration = () => navigate('/registration');

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch( href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      } else{
        navigate('/')
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])

  return (
    <div className='home_page_container'>
      <CategoryPanel showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} visible={true} setLocate={setLocate}/>
      <div className='main_content'>
        {showCategoryPanel && <div className='dark_overlay'></div>}
        <Header isUserLogged={isUserLogged} isLarge={false} toggleCategoryPanel={toggleCategoryPanel} setIsUserLogged={setIsUserLogged} ProfileIcon={ProfileIcon}/>
        {!isUserLogged && <UnregisteredUserContent goToRegistration={goToRegistration} />}
        <div className='projects_title_container'>
          <h3 className='projects_description'>Проекты для вас</h3>
          <img className='smile_img' src={Smile} alt='smile' />
        </div>
        <div className='projects_list_container'>
          <ProjectsList Locate={Locate}/>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
