import { href } from '../../pages/href.js'

const TelegramLogin = () => {

  return (
    <div>
      <script async src="https://telegram.org/js/telegram-widget.js?22" data-telegram-login="InspirionBot" data-size="medium" data-userpic="false" data-auth-url={href + "auth/telegram"} data-request-access="write"></script>
    </div>
  );
};

export default TelegramLogin;
