import React from 'react';
import { modalCategories } from './modalCategories';

import './style.css';

// Компонент, отвечающий за выбор категорий проект

function ModalCategories({ onSelect }) {
  return (
    <div className='modal_content'>
      <h2 className='modal_title'>Что создаем?</h2>
      <div className='modal_categories_container'>
        {modalCategories.map((category, index) => (
          <div onClick={() => onSelect(category.text)} key={index} className='modal_category_item'>
            {category.image}
            <p className='modal_category_title'>{category.text}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ModalCategories