import React, { useState } from 'react';

import './style.css';

// Компонент который отображает цель доната

const DonationGoal = ({ startGoal, finalGoal, currentValue }) => {
  const [sliderValue, setSliderValue] = useState(currentValue);

  const calculatePosition = () => {
    if (sliderValue < finalGoal){
      const percentage = ((sliderValue - startGoal) / (finalGoal - startGoal)) * 100;
      const offset = (percentage / 100) * 8 - 4;
      return `calc(${percentage}%  - ${offset}px)`;
    } else{
      const percentage = 105;
      const offset = (percentage / 100) * 8 - 4;
      return `calc(${percentage}%  - ${offset}px)`;
    }
  };

  return (
    <div className='donation_slider'>
      <div className='slider_track'></div>
      <div className='slider_line'></div>
      <div className='slider_dot left_dot'></div>
      <div className='slider_dot right_dot'></div>
      <div className='slider_labels'>
        <span className='slider_label'>{startGoal} ₽</span>
        <span className='slider_label'>{finalGoal} ₽</span>
      </div>
      <div className='slider_value_indicator' style={{ left: calculatePosition() }}>
        {sliderValue + ' ₽'.replace(/ /g, "\u00A0")}
      </div>
    </div>
  );
};

export default DonationGoal;
