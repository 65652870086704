// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login_container {
  margin-top: 110px;
  width: 571px;
  margin-left: auto;
  margin-right: auto;
}

.login {
  margin-top: 92px;
  margin-bottom: 40px;
}

.mobile_text_description {
  display: none;
  font-family: var(--main-font);
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 20px;
}

@media (min-width: 0px) and (max-width: 743px) {
  .login_container {
    width: 320px;
    margin-top: 50px;
  }

  .mobile_text_description {
    display: block;
    color: var(--font-color);
  }

  .login {
    margin-top: 40px;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Login/style.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,YAAY;IACZ,gBAAgB;EAClB;;EAEA;IACE,cAAc;IACd,wBAAwB;EAC1B;;EAEA;IACE,gBAAgB;EAClB;AACF","sourcesContent":[".login_container {\r\n  margin-top: 110px;\r\n  width: 571px;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n\r\n.login {\r\n  margin-top: 92px;\r\n  margin-bottom: 40px;\r\n}\r\n\r\n.mobile_text_description {\r\n  display: none;\r\n  font-family: var(--main-font);\r\n  font-size: 16px;\r\n  font-weight: 400;\r\n  text-align: center;\r\n  margin-top: 40px;\r\n  margin-bottom: 20px;\r\n}\r\n\r\n@media (min-width: 0px) and (max-width: 743px) {\r\n  .login_container {\r\n    width: 320px;\r\n    margin-top: 50px;\r\n  }\r\n\r\n  .mobile_text_description {\r\n    display: block;\r\n    color: var(--font-color);\r\n  }\r\n\r\n  .login {\r\n    margin-top: 40px;\r\n  }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
