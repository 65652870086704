import user from '../Header/assets/user.svg'

export const modalUser = {
  vk: '',
  tg: '',
  image: user,
};

export const openModal = (setIsModalOpen) => {
  if (modalUser.vk && modalUser.tg) {
    window.open('https://web.telegram.org/', '_blank'); 
  } else if (modalUser.vk) {
    window.open('https://vk.com', '_blank');
  } else if (modalUser.tg) {
    window.open('https://web.telegram.org/', '_blank');
  } else {
    setIsModalOpen(true);
  }
};
