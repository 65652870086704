// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal_categories_container {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  margin: auto;
  margin-top: 40px;
  gap: 30px;
}

.modal_category_item {
  width: 180px;
  height: 201px;
  cursor: pointer;
  transition: transform 0.5s ease; 
}

.modal_category_item:hover {
  transform: scale(1.1); 
}

.modal_title, .modal_category_title {
  font-family: var(--main-font);
  font-weight: 600;
  line-height: normal;
  color: var(--font-color);
}

.modal_title {
  font-size: 32px;
  margin-top: 50px;
}

.modal_category_title {
  font-size: 21px;
  margin-top: 20px;
}

.modal_category_image {
  width: 50px;
  height: 50px;
  padding: 25px;
  border-radius: 100px;
  background-color: rgba(192, 192, 192, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/components/ModalCategories/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;EACf,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,eAAe;EACf,+BAA+B;AACjC;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,6BAA6B;EAC7B,gBAAgB;EAChB,mBAAmB;EACnB,wBAAwB;AAC1B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,aAAa;EACb,oBAAoB;EACpB,0CAA0C;AAC5C","sourcesContent":[".modal_categories_container {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n  width: 600px;\r\n  margin: auto;\r\n  margin-top: 40px;\r\n  gap: 30px;\r\n}\r\n\r\n.modal_category_item {\r\n  width: 180px;\r\n  height: 201px;\r\n  cursor: pointer;\r\n  transition: transform 0.5s ease; \r\n}\r\n\r\n.modal_category_item:hover {\r\n  transform: scale(1.1); \r\n}\r\n\r\n.modal_title, .modal_category_title {\r\n  font-family: var(--main-font);\r\n  font-weight: 600;\r\n  line-height: normal;\r\n  color: var(--font-color);\r\n}\r\n\r\n.modal_title {\r\n  font-size: 32px;\r\n  margin-top: 50px;\r\n}\r\n\r\n.modal_category_title {\r\n  font-size: 21px;\r\n  margin-top: 20px;\r\n}\r\n\r\n.modal_category_image {\r\n  width: 50px;\r\n  height: 50px;\r\n  padding: 25px;\r\n  border-radius: 100px;\r\n  background-color: rgba(192, 192, 192, 0.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
