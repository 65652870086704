import { useNavigate } from 'react-router-dom';
import DonationGoal from '../DonationGoal';

import './style.css';

// Компонент, который отвечает за информацию и кнопку доната

function DonationInfo ({donatsCount, donatsSum, startGoal, finalGoal, title}) {
  const navigate = useNavigate()

  const goToPaymentPage = () => {
    navigate(`/payment?query=${title}`);
  };

  return (
    <>
      <div className='donation_content_wrapper'>
        <div className='donat_info_container'>
          <div className='donats_sum_container_left'>
            <p className='donat_text'>{donatsCount}</p>
            <p className='donat_description'>Количество донатов</p>
          </div>
          <div className='donats_sum_container'>
            <p className='donat_text'>{donatsSum} ₽</p>
            <p className='donat_description'>Сумма донатов</p>
          </div>
        </div>
        <DonationGoal startGoal={startGoal} finalGoal={finalGoal} currentValue={donatsSum}></DonationGoal>
        <button className='donat_button' onClick={goToPaymentPage}>Задонатить</button>
      </div>
    </>
  );
}

export default DonationInfo;
