// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file_img {
  width: 60px;
  height: 80px;
}

.file_title {
  font-family: var(--main-font);
  font-size: 18px;
  color: var(--font-color);
  text-overflow: ellipsis;
  overflow: hidden;
  width: 149px;
}

.file_size {
  font-size: 14px;
  color: var(--secondary-text-color);
  font-family: var(--main-font);
}`, "",{"version":3,"sources":["webpack://./src/components/FileInput/style.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,wBAAwB;EACxB,uBAAuB;EACvB,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,eAAe;EACf,kCAAkC;EAClC,6BAA6B;AAC/B","sourcesContent":[".file_img {\r\n  width: 60px;\r\n  height: 80px;\r\n}\r\n\r\n.file_title {\r\n  font-family: var(--main-font);\r\n  font-size: 18px;\r\n  color: var(--font-color);\r\n  text-overflow: ellipsis;\r\n  overflow: hidden;\r\n  width: 149px;\r\n}\r\n\r\n.file_size {\r\n  font-size: 14px;\r\n  color: var(--secondary-text-color);\r\n  font-family: var(--main-font);\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
