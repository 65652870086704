import './style.css'

// Комопнент, отображающий айтем нотификации

const Notification = ({ text, openModal }) => {
  return (
    <div className='notification'>
      <div className='notification_img'></div>
      <div className='notification_text_container'>
        <h6 className='notification_text'>{text}</h6>
        <p onClick={openModal} className='notification_description'>Подробнее в личном кабинете</p>
      </div>
    </div>
  );
};

export default Notification