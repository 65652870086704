import React, { useEffect, useState, useCallback } from 'react';
import CategoryItem from '../CategoryItem';
import ModalContentContainer from '../ModalContentContainer';
import ModalPersonalAccount from '../ModalPersonalAccount';
import vk from './assets/vk.svg'
import tg from './assets/tg.svg'

import { categories } from './categories';
import { openModal } from '../ModalPersonalAccount/openModal';
import { ReactComponent as X } from './assets/x.svg'
import { useLocation, useNavigate } from 'react-router-dom';

import './style.css';

// Компонент, который отображает панель категорий

function CategoryPanel({ showCategoryPanel, setShowCategoryPanel, visible, setLocate }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [selectedCategory, setSelectedCategory] = useState('/');
  const [closing, setClosing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setSelectedCategory(pathname || '/');
    try {
      setLocate(pathname || '/')
    } catch (err) {}
  }, [pathname]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showCategoryPanel && !event.target.closest('.categories_container')) {
        setShowCategoryPanel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showCategoryPanel, setShowCategoryPanel]);

  const handleCategoryClick = useCallback((url) => {
    setSelectedCategory(url);
    setClosing(true);
    setTimeout(() => {
      setShowCategoryPanel(false);
      setClosing(false);
    }, 1);
    navigate(url === '' ? '/' : url);
  }, [navigate, setShowCategoryPanel]);

  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToCreatePage = () => {
    navigate('/create_project')
  }

  return (
    <div className={`categories_container ${showCategoryPanel ? 'open_panel' : (closing ? 'close_panel' : '')}  ${visible ? 'visible_panel' : 'hidden_panel'}`}>
      <div className='categories_wrapper'>
        <div className='categories_panel_x' onClick={() => setShowCategoryPanel(false)}>
          <X />
        </div>
        {categories.map((category, index) => (
          <CategoryItem
            key={index}
            categoryTitle={category.categoryTitle}
            onClick={() => handleCategoryClick(category.url)}
            img={category.img}
            selected={selectedCategory === category.url}
          />
        ))}
      </div>
      <button onClick={goToCreatePage} className='create_project_btn_mobile'>СОЗДАТЬ ПРОЕКТ</button>
      <div className='categories_panel_help_container'>
        <div className='categories_panel_buttons_container'>
          <img className='categories_panel_vk' onClick={() => openInNewTab('https://vk.com')} src={vk} alt='vk' />
          <img className='categories_panel_tg' onClick={() => openInNewTab('https://web.telegram.org/')} src={tg} alt='tg' />
        </div>
        <h2 className='help_title'>Есть вопрос?</h2>
        <p className='help_description'>Наша поддержка работает быстро, если у Вас есть вопрос - пишите</p>
        <button type='button' className='help_btn' onClick={() => openModal(setIsModalOpen)}>ЛИЧНЫЙ КАБИНЕТ</button>
      </div>
      {isModalOpen &&
        <ModalContentContainer onClose={closeModal}>
          <ModalPersonalAccount></ModalPersonalAccount>
        </ModalContentContainer>
      }
    </div>
  );
}

export default CategoryPanel;
