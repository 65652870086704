import React, { useEffect, useState } from 'react';
import { useTheme } from '../ThemeContex';
import darkUser from './assets/no_user_dark.svg'
import lightUser from './assets/no_user_light.svg'

import './style.css'

// Компонент, отвечающий для загрузки фото пользователя в настройках пользователя

function UserProfileImageInput({setProfileIcon, ProfileIcon}) {
  const [fileError, setFileError] = useState('');
  const {theme} = useTheme()
  const [coverImage, setCoverImage] = useState(ProfileIcon);

  useEffect(() => {
    if (coverImage == '') {
      setCoverImage(theme === 'dark' ? darkUser : lightUser)
    }
  }, [theme])

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const allowedTypes = ['image/jpeg', 'image/jpg', 'image/png'];
      if (!allowedTypes.includes(file.type)) {
        setFileError('Разрешены только файлы с расширением .jpeg, или .jpg., или .png');
        setTimeout(() => setFileError(''), 5000);
        event.target.value = '';
      } else if (file.size > 5 * 1024 * 1024) {
        setFileError('Размер файла не должен превышать 5 МБ.');
        setTimeout(() => setFileError(''), 5000);
        event.target.value = '';
      } else {
        setFileError('');
        setCoverImage(URL.createObjectURL(file));
      setProfileIcon(file)
      }
    }
  };

  return (
    <div>
      <div className='profile_image_input_container' style={{ backgroundImage: `url(${coverImage})` }}>
        <input type='file' onChange={handleFileChange} className='profile_image_input'/>
      </div>
      {fileError && <p className='user_profile_file_error'>{fileError}</p>}
    </div>
  );
}

export default UserProfileImageInput;
