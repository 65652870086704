import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import ProjectsList from '../../components/ProjectsList';
import Loader from '../../components/Loader';
import CategoryPanel from '../../components/CategoryPanel';
import useWindowWidth from '../../hooks/useWindowWidth';
import MobileSelect from '../../components/MobileSelect';
import useWindowWidthPanel from '../../components/CategoryPanel/useWindowWidth';
import useLockBodyScroll from '../../hooks/useBodyLock';
import { useLocation } from 'react-router-dom';
import { ReactComponent as Info } from '../../components/ProjectPageDesktop/assets/info.svg';

import { mockUser } from './mockUser';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Country } from './assets/country.svg';
import { ReactComponent as Inst } from './assets/instagram.svg';
import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { useUserLogged } from '../Home/useUserLoggedState';
import { href } from '../href.js'

import './style.css';

function UserProfilePage() {
  const navigate = useNavigate();
  const [ options, setOptions ] = useState([
    { label: 'Мои проекты', value: 'myProjects' },
    { label: 'Оценки', value: 'assessments' },
    { label: 'Донаты', value: 'donations' }
  ])
  const [selectedCategory, setSelectedCategory] = useState(options[0].value);
  const [isLoading, setIsLoading] = useState(true);
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const { isUserLogged, setIsUserLogged } = useUserLogged();
  const width = useWindowWidth();
  const visible = useWindowWidthPanel();
  const [link, setLink] = useState('')
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [ProfileIcon, setProfileIcon] = useState('');
  const [ProfileCap, setProfileCap] = useState('');
  const [country, setCountry] = useState('');
  const [money, setMoney] = useState('');
  const location = useLocation();
  const userParams = new URLSearchParams(location.search);
  const userName = userParams.get('query') || '';
  const [ otherUser, setOtherUser] = useState('')
  const [userIcon, setUserIcon] = useState('');

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  const goToUserProfileSettings = () => {
    navigate('/user_profile_settings');
  };

  const goToPaymentPage = () => {
    navigate('/payment');
  };

  useEffect(() => {
    setIsLoading(true);

    if (userName != '') {
      setOptions([
        { label: 'Проекты пользователя', value: 'myProjects' },
      ])
    }

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt,
            userName: userName
          };

          try {
            const response = await fetch(href + 'user_profile', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setName(result.name)
              setLink(result.link)
              setDescription(result.description)
              setProfileIcon(result.path_profile_icon)
              setProfileCap(result.path_profile_cap)
              setCountry(result.country)
              setMoney(result.money)
              setOtherUser(result.other)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();

        const fetchDataIcon = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch(href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setUserIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchDataIcon();
      } else{
        navigate('/')
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  }, []);

  const redirectToInstagramProfile = () => {
    window.open(`${link}`, '_blank');
  };

  useLockBodyScroll(showCategoryPanel);

  const complaint = () => {
     const fetchDataComplaint = async (event) => {
      const projectData = {
        userName: userName,
        jwt: localStorage.jwt
      };

      try {
        const response = await fetch(href + 'complaint', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(projectData),
        });

        const result = await response.json();
      } catch (error) {
        console.error('Ошибка:', error);
      }
    }

    fetchDataComplaint();
  };

  return (
    <div className='user_profile_wrapper'>
      <CategoryPanel visible={visible} showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} />
      <Header isUserLogged={isUserLogged} isLarge={true} toggleCategoryPanel={toggleCategoryPanel} setIsUserLogged={setIsUserLogged} ProfileIcon={userIcon}/>
      {showCategoryPanel && <div className='dark_overlay'></div>}
      {isLoading ? (
        <Loader loaderText='Загружаем Вашу страницу' />
      ) : (
        <div className='user_profile_main_container'>
          <div className='user_photos_wrapper'>
            <img className='user_profile_background' src={ProfileCap} alt='user profile' />
            <img className='user_profile_image' src={ProfileIcon} alt='user img' />
            <div className='user_info_container'>
              <div className='user_info_left'>
                <p className='user_name'>{name}</p>
                <p className='user_description'>{description}</p>
                <div className='user_links_container'>
                  <div className='country_wrapper'>
                    <div className='country' >
                      <Country />
                    </div>
                    <p className='country_text'>{country}</p>
                  </div>
                  <div className='instagram_container'>
                    <div className='inst_img' >
                      <Inst />
                    </div>
                    <p className='inst_text' onClick={redirectToInstagramProfile}>{link}</p>
                  </div>
                </div>
              </div>
              <div>
                {!otherUser && <button onClick={goToUserProfileSettings} className='settings_button'>НАСТРОЙКИ</button>}
                {!otherUser && <img className='settings_mobile' onClick={goToUserProfileSettings} src='/UserProfileAssets/settings_mobile.svg' alt='country' />}
                {!otherUser && <div className='amount_container' onClick={goToPaymentPage}>
                  <img className='wallet_img' src='/UserProfileAssets/wallet.svg' alt='wallet'/>
                  <div>
                    <p className='amount_text'>ОСТАТОК</p>
                    <p className='amount_value'>{money}</p>
                  </div>
                </div>}
                {otherUser && <Info />}
                {otherUser && <button onClick={() => complaint()} className='report_button'>ПОЖАЛОВАТЬСЯ</button>}
              </div>
            </div>
            <div className='amount_container_mobile'>
              <img className='wallet_img' src='/UserProfileAssets/wallet.svg' alt='wallet' />
              <div>
                <p className='amount_text'>ОСТАТОК</p>
                <p className='amount_value'>{money}</p>
              </div>
            </div>
          </div>
          <div className='project_list_wrapper_profile'>
            <div className='category_buttons'>
              {options.map(option => (
                <button
                  key={option.value}
                  className={'category_button' + (selectedCategory === option.value ? ' active_category' : '')}
                  onClick={() => handleCategoryChange(option.value)}
                >
                  {option.label}
                </button>
              ))}
              {width < 743 && <MobileSelect
                options={options}
                title={''}
                value={selectedCategory}
                onChange={(selectedValue) => handleCategoryChange(selectedValue)}
              />}
            </div>
            {selectedCategory === 'myProjects' && <ProjectsList Locate={selectedCategory} name={userName}/>}
            {selectedCategory === 'assessments' && <ProjectsList Locate={selectedCategory} name={userName}/>}
            {selectedCategory === 'donations' && <ProjectsList Locate={selectedCategory} name={userName}/>}
          </div>
        </div>
      )}
    </div>
  );
}

export default UserProfilePage;
