import React, { useState } from 'react';

import './style.css';

// Комопонент, отобажающий селектор (страны, языка, темы)

const Select = ({ options, title, value, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (option) => {
    onChange(option.value);
    setIsOpen(false);
  };

  return (
    <div className='select_container'>
      <h4 className='select_title'>{title}</h4>
      <div className='custom_select_container'>
        <div className={`custom_select_content ${isOpen ? 'selected_option' : ''}`} onClick={toggling}>
          {options.find(option => option.value === value)?.label || options[0]?.label}
          <div className={`arrow ${isOpen ? 'open' : ''}`}>&#9660;</div>
        </div>
        {isOpen && (
          <div className='custom_options'>
            {options.map(option => (
              <div key={option.value} onClick={() => onOptionClicked(option)} className='custom_option'>
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Select;
