// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.password-input-container {
  position: relative;
}

.password-toggle-button {
  position: absolute;
  right: 20px;
  top: 24px;
  background-color: white;
  border: none;
  background-repeat: no-repeat;
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.error_message {
  color: red;
  margin-top: -20px;
  margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/PasswordInput/style.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,uBAAuB;EACvB,YAAY;EACZ,4BAA4B;EAC5B,eAAe;EACf,WAAW;EACX,YAAY;AACd;;AAEA;EACE,UAAU;EACV,iBAAiB;EACjB,mBAAmB;AACrB","sourcesContent":[".password-input-container {\r\n  position: relative;\r\n}\r\n\r\n.password-toggle-button {\r\n  position: absolute;\r\n  right: 20px;\r\n  top: 24px;\r\n  background-color: white;\r\n  border: none;\r\n  background-repeat: no-repeat;\r\n  cursor: pointer;\r\n  width: 20px;\r\n  height: 20px;\r\n}\r\n\r\n.error_message {\r\n  color: red;\r\n  margin-top: -20px;\r\n  margin-bottom: 10px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
