import { ReactComponent as Code } from '../CategoryPanel/assets/code.svg';
import { ReactComponent as Cube } from '../CategoryPanel/assets/cube.svg';
import { ReactComponent as Cpu } from '../CategoryPanel/assets/cpu.svg';
import { ReactComponent as Draw } from '../CategoryPanel/assets/draw.svg';
import { ReactComponent as Computer } from '../CategoryPanel/assets/computer.svg';
import { ReactComponent as User } from '../CategoryPanel/assets/user.svg';

export const modalCategories = [
  { text: 'Видеоигры', image: <Cube className='modal_category_image'/> },
  { text: 'ПО', image: <Code className='modal_category_image' /> },
  { text: 'Инженерные проекты', image: <Cpu className='modal_category_image' />},
  { text: 'Творчество', image: <Draw className='modal_category_image' /> },
  { text: 'Web-приложения', image: <Computer className='modal_category_image' /> },
  { text: 'Социальные проекты', image: <User className='modal_category_image' /> }
];
