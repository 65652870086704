// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projects_title_container {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 50px;
  margin-left: 50px;
}

.projects_description {
  font-family: var(--main-font);
  font-size: 32px;
  font-weight: 600;
  line-height: 38px;
  color: var(--font-color);
}

.smile_img {
  width: 40px;
  height: 40px;
  margin-left: 10px
}

.projects_list_wrapper {
  display: flex;
  flex-wrap: wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectsList/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,mBAAmB;EACnB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,6BAA6B;EAC7B,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,wBAAwB;AAC1B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ;AACF;;AAEA;EACE,aAAa;EACb,eAAe;AACjB","sourcesContent":[".projects_title_container {\r\n  display: flex;\r\n  align-items: center;\r\n  margin-bottom: 40px;\r\n  margin-top: 50px;\r\n  margin-left: 50px;\r\n}\r\n\r\n.projects_description {\r\n  font-family: var(--main-font);\r\n  font-size: 32px;\r\n  font-weight: 600;\r\n  line-height: 38px;\r\n  color: var(--font-color);\r\n}\r\n\r\n.smile_img {\r\n  width: 40px;\r\n  height: 40px;\r\n  margin-left: 10px\r\n}\r\n\r\n.projects_list_wrapper {\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
