import Notification from '../NotificationItem';
import { notifications } from './notifications';

import './style.css'

// Контейнер для нотификаций

const NotificationContainer = ({ openModal }) => {
  return (
    <div className='notification_container'>
      {notifications.map((notification, index) => (
        <Notification openModal={openModal} key={index} text={notification.text} />
      ))}
    </div>
  );
};

export default NotificationContainer