import React, { useEffect, useRef, useState } from 'react';
import NotificationContainer from '../NotificationContainer';
import ModalPersonalAccount from '../ModalPersonalAccount';
import ModalContentContainer from '../ModalContentContainer';
import useWindowWidth from '../../hooks/useWindowWidth';

import { useNavigate } from 'react-router-dom';
import { modalUser, openModal } from '../ModalPersonalAccount/openModal';
import { useError } from '../ErrorProvider';
import { ReactComponent as Logo } from './assets/logo.svg'
import { ReactComponent as Menu } from './assets/menu.svg'
import { ReactComponent as SearchMobile } from './assets/search_mobile.svg'
import { ReactComponent as Notification } from './assets/notification.svg'
import { ReactComponent as Door } from './assets/door.svg'

import './style.css'; 

// Компонент, отображающий шапку профиля

function Header({ isUserLogged, isLarge, toggleCategoryPanel, setIsUserLogged, isNotificationOpen, closeNotificationModal, ProfileIcon }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [showNotifications, setShowNotifications] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const modalRef = useRef(null);
  const screenWidth = useWindowWidth();
  const { setErrorMsg } = useError()
  const [isInputVisible, setInputVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowNotifications(false)

        if (closeNotificationModal) {
          closeNotificationModal()
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalRef, closeNotificationModal]); 

  const handleDoor = (event) => {
    localStorage.setItem('jwt', '');
    setIsUserLogged(false)
    navigate('/')
  }; 

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const goToUserProfile = () => {
    navigate('/user_profile');
  };

  const goToHomePage = () => {
    navigate('/');
  };

  const goCreateProjectPage = () => {
    navigate('/create_project');
  };

  const goToSearchPage = () => {
    if (searchQuery.trim() !== '') {
      navigate(`/search?query=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  const goToRegistrationPage = () => {
    navigate('/registration')
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      goToSearchPage();
    }
  };

  const handleChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const toggleNotifications = () => {
    setShowNotifications((prevState) => !prevState);

    // Раскоментируйте код ниже, для того, чтобы при нажатии на уведолмения отобразилось сообщение о том, что сайт упал
    // setErrorMsg();
  };

  const toggleInput = () => {
    setInputVisible(!isInputVisible);
  };

  return (
    <header className='header'>
      <div className='logo' onClick={goToHomePage}>
        <Logo />
      </div>
      <input
        className={`search_input ${isLarge ? 'large' : ''}`}
        value={searchQuery}
        onChange={handleChange}
        placeholder='Поиск'
        onKeyDown={handleKeyDown}
        maxLength={128}
      />
      <div className={`header_buttons_container ${!isUserLogged ? 'logged' : ''}`}>
      {screenWidth >= 0 && screenWidth <= 743 ? (
        <>
          {isInputVisible && (
            <input
              value={searchQuery}
              onChange={handleChange}
              placeholder="Поиск"
              onKeyDown={handleKeyDown}
              className='mobile_search_input'
              maxLength={128}
            />
          )}
           <div className='mobile_search_button' onClick={toggleInput}>
            <SearchMobile />
           </div>
        </>
      ) : null}
        <button type='button' onClick={() => openModal(setIsModalOpen)} className='header_help_button'>ЛИЧНЫЙ КАБИНЕТ</button>
        {isUserLogged ? (
          <button className='header_start_button' onClick={goCreateProjectPage}>Создать проект</button>
        ) : (
          <button className='header_start_button' onClick={goToRegistrationPage}>Начать</button>
        )}
        {isUserLogged && (
         <>
          <div className='header_quit_button' onClick={handleDoor}>
            <Door />
          </div>
          <div className='notification_container' ref={modalRef}>
            <div
              className='header_notification_button'
              onClick={toggleNotifications}
              alt='notification'
            >
              <Notification />
            </div>
            {(showNotifications || isNotificationOpen) && (
              <div className='notification_list'>
                <NotificationContainer openModal={() => openModal(setIsModalOpen)} />
              </div>
            )}
          </div>
          <img className='header_user_button' src={ProfileIcon} onClick={goToUserProfile} alt='user profile'></img>
        </>
        )}
        <div className='header_menu_button' onClick={toggleCategoryPanel} >
          <Menu />
        </div>
      </div>
      {isModalOpen &&
        <ModalContentContainer onClose={closeModal}>
          <ModalPersonalAccount></ModalPersonalAccount>
        </ModalContentContainer>
      }
    </header>
  );
}

export default Header;
