import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import './style.css';
import Header from '../../components/Header';
import { useUserLogged } from '../Home/useUserLoggedState';
import { useCategoryPanelContext } from '../../components/CategoryPanelContext';
import { href } from '../href.js'

function PaymentPage() {
  const [amount, setAmount] = useState('');
  const {isUserLogged, setIsUserLogged} = useUserLogged();
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const [ProfileIcon, setProfileIcon] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [link, setLink] = useState('')
  const location = useLocation();
  const projectParams = new URLSearchParams(location.search);
  const projectName = projectParams.get('query') || '';

  const navigate = useNavigate()

  useEffect(()=> {
    setIsLoading(true);

    try{
      if (localStorage.jwt !== ''){
        setIsUserLogged(true)
        const fetchData = async (event) => {
          const settingsData = {
            jwt: localStorage.jwt
          };

          try {
            const response = await fetch( href + 'icon', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(settingsData),
            });

            const result = await response.json();
            if (response.ok) {
              setProfileIcon(result.path_profile_icon)
              setIsLoading(false);
            } else {
              throw new Error(result.msg);
            }
          } catch (error) {
            console.error('Ошибка:', error);
            setIsLoading(false);
          }
        }
        fetchData();
      } else{
        navigate('/')
      }
    } catch (error) {
        console.error('Ошибка:', error);
    }
  },[])
  
  const handleAmountChange = (event) => {
    let value = event.target.value;
    value = value.replace(/\D/g, '');
    
    if (value !== '' && value.length > 3) {
      value = value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    setAmount(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    
    const fetchProjectData = async (event) => {
      const formData = {
        jwt: localStorage.jwt,
        amount: amount,
        projectName: projectName
      }

      try {
        const response = await fetch(href + 'payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        });

        const result = await response.json();
        if (response.ok) {
          setLink(result.link)
        } else {
          throw new Error(result.msg);
        }
      } catch (error) {
        console.error('Ошибка:', error);
        setIsLoading(false);
      }
    }
    fetchProjectData();
  };

  return (
    <form className='payment_container' onSubmit={handleSubmit}>
      <Header
        isUserLogged={isUserLogged} isLarge={true} toggleCategoryPanel={toggleCategoryPanel}
        setIsUserLogged={setIsUserLogged} ProfileIcon={ProfileIcon}/>
      <div className='payment_wrapper'>
        <h1 className='payment_title'>Оплата</h1>
        <div>
          <h3 className='payment_input_description'>Сумма</h3>
          <input
            onChange={handleAmountChange}
            value={amount}
            maxLength={128}
            required
            className='payment_input donat_input' 
            placeholder='Введите сумму доната'>
          </input>
          <span className='amount_suffix'>|</span>
          <span className='amount_suffix currency'>₽</span>
        </div>
        {!link && <button className='payment_submit_btn' type='submit'>ПОДТВЕРДИТЬ</button>}
        {link && (<div>
          <a className='payment_link' href={link}>
           <div className='payment_submit_batton'>
              <h3 className='payment_text'>Нажмите для оплаты</h3>
            </div>
          </a>
        </div>)}
      </div>
    </form>
  );
}

export default PaymentPage;
