import './style.css';

// Компонент, для отображения дополнительных файлов проекта

function ProjectFiles({ anotherFiles }) {
  return (
    <>
      <h5 className='presentation_text'>Документация</h5>
      {anotherFiles.map((file, index) => (
        <div key={index} className='files_container'>
          <a className='files_container' href={file.title} download>
            <img className='doc_img' src={'/ProjectPageAssets/file.png'} alt={`File ${index}`} />
            <div>
              <h6 className='presentation_title'>{file.title.substring(file.title.lastIndexOf('/') + 1)}</h6>
              <p className='presentation_size'>{file.size}</p> 
            </div>
          </a>
        </div>
      ))}
    </>
  );
}

export default ProjectFiles;
