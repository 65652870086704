import React, { createContext, useContext, useState } from 'react';

// Компонент, который создает контекст для открытия и закрытия панели категорий в приложении

const CategoryPanelContext = createContext();

export function useCategoryPanelContext() {
  return useContext(CategoryPanelContext);
}

export function CategoryPanelProvider({ children }) {
  const [showCategoryPanel, setShowCategoryPanel] = useState(false);

  const toggleCategoryPanel = () => {
    setShowCategoryPanel(prevState => !prevState);
  };

  return (
    <CategoryPanelContext.Provider value={{ showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel }}>
      {children}
    </CategoryPanelContext.Provider>
  );
}
