import React, { useState } from 'react';

import './style.css';

// Компонент, который отображает конкретный айтем в панели категорий

function CategoryItem({ categoryTitle, img, onClick, selected }) {
  const [isHovered, setIsHovered] = useState(false);
  
  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);
  
  const categoryClassName = selected ? 'category selected' : 'category';

  return (
    <div 
      className={categoryClassName}
      onClick={onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className={`category_icon ${isHovered ? 'hovered_icon' : ''}`}>
        {img}
      </div>
      <h3 className='category_name'>{categoryTitle}</h3>
    </div>
  );
}

export default CategoryItem