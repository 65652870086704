import React, { createContext, useContext, useState } from 'react';
import useLockBodyScroll from '../../hooks/useBodyLock';

import './style.css';

// Компонент, который отображает ошибку, когда сайт упал

const ErrorContext = createContext();

export const ErrorProvider = ({ children }) => {
  const [error, setError] = useState(null);

  useLockBodyScroll(!!error);

  const setErrorMsg = () => {
    setError('Сайт упал');
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, setErrorMsg, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
};

export const useError = () => {
  const context = useContext(ErrorContext);
  if (!context) {
    throw new Error('useError must be used within an ErrorProvider');
  }
  return context;
};

export const ErrorComponent = () => {
  const { error } = useError();

  return (
    <>
      {error && (
        <div className='error_component'>
          <p className='error_msg'>{error}</p>
        </div>
      )}
    </>
  );
};
