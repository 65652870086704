import CategoryPanel from '../CategoryPanel';
import Header from '../Header';
import Slider from '../Slider';
import DonationInfo from '../DonationInfo';
import EducationalInfo from '../EducationalInfo';
import DonatersList from '../DonatersList';
import ProjectVideos from '../ProjectVideos';
import ProjectFiles from '../ProjectFiles';
import Loader from '../Loader';
import { useTheme } from '../ThemeContex';
import useLockBodyScroll from '../../hooks/useBodyLock';
import { useUserLogged } from '../../pages/Home/useUserLoggedState';
import { useCategoryPanelContext } from '../CategoryPanelContext';
import useProjectData from '../../pages/Project/useProjectData';
import { ReactComponent as Arrow } from '../ProjectPageDesktop/assets/arrow.svg';
import { ReactComponent as Hands } from '../ProjectPageDesktop/assets/hands.svg';
import { ReactComponent as Info } from '../ProjectPageDesktop/assets/info.svg';

import './style.css';

// Мобильная и планшетная версия ProjectPage

const ProjectPageTabletAndMobile = ({ ProfileIcon }) => {
  const { showCategoryPanel, setShowCategoryPanel, toggleCategoryPanel } = useCategoryPanelContext();
  const { loading, project, goToUserProfile, goToLink, goToSearchPage, handleLike,
    liked, isModalOpen, closeModal, openModal } = useProjectData();
    const {theme} = useTheme()

    useLockBodyScroll(showCategoryPanel);
    const {isUserLogged, setIsUserLogged} = useUserLogged();
  
  return (
    <div className='project_page_container_tablet'>
      <CategoryPanel showCategoryPanel={showCategoryPanel} setShowCategoryPanel={setShowCategoryPanel} visible />
      <Header isUserLogged={isUserLogged} isLarge={false} setIsUserLogged={setIsUserLogged} toggleCategoryPanel={toggleCategoryPanel} isNotificationOpen={isModalOpen} closeNotificationModal={closeModal} ProfileIcon={ProfileIcon}/>
      <div className='project_page_tablet_wrapper'>
        {loading && <Loader loaderText='Загружаем проект' />}
        {showCategoryPanel && <div className='dark_overlay' />}
        <div className='project_page_tablet_info_container'>
          {project && (
            <>
              <div className='project_page_title_info_container'>
                <h3 className='project_page_title'>
                  {project.title}
                  {project.educational && (theme === 'dark' ?  <img src='/ProjectPageAssets/learnmark_dark.svg' className='learn_mark' alt='learnmark' /> :
                  <img src='/ProjectPageAssets/learnmark_light.svg' className='learn_mark' alt='learnmark' />)}
                </h3>
                <div className='tags_container_tablet'>
                  {project.tags.map((tag, index) => (
                    <p className='tag' onClick={() => goToSearchPage(tag)} key={index}>{tag}</p>
                  ))}
                </div>
                <div className='links_container'>
                  {project.links.map((link, index) => (
                    <div className='links_wrapper' key={index} onClick={() => goToLink(link)}>
                      <div className='link_img'>
                        <Arrow />
                      </div>
                      <p className='link_text'>{link}</p>
                    </div>
                  ))}
                </div>
                <div className='likes_container'>
                <div
                    className='like_img'
                    onClick={handleLike}
                  >
                    {liked ? <Hands /> : <img src='/ProjectPageAssets/hands_liked.svg' alt='hands'/>}
                  </div>
                  <p className='like_text'>{project.likes} оценили</p>
                </div>
              </div>
              <Slider images={project.projectImages} />
            </>
          )}
        </div>
        {project && (
          <div className='project_page_author_container_tablet'>
            <img onClick={goToUserProfile} src={project.authorImg} alt='author' className='project_page_author' />
            <p onClick={goToUserProfile} className='project_page_author_name'>{project.author}</p>
            {project.educational && <p className='project_page_account_type_tablet'>УЧЕБНЫЙ ПРОЕКТ</p>}
            <div>
              <Info />
            </div>
            <button onClick={() => openModal()} className='report_button'>ПОЖАЛОВАТЬСЯ</button>
          </div>
        )}
        {project && !project.educational && <DonationInfo {...project} />}
        {project && <DonatersList donaters={project.donaters}/>}
        {project && project.educational && <EducationalInfo donatsCount={project.donatsCount} donatsSum={project.donatsSum} projectName={project.title}/>}
        {project && <ProjectVideos projectVideos={project.projectVideos} />}
        {project && (
          <div className='project_info_tablet_wrapper'>
            <p className='project_text_description'>{project.description}</p>
            <h6 className='project_title_text'>Цель по развитию</h6>
            <p className='project_text_description'>{project.goalText}</p>
            {!project.educational && <h6 className='project_title_text'>Что получите за донат</h6>}
            {!project.educational && <p className='project_text_description'>{project.donatText}</p>}
            {project.presentation.title && <h5 className='presentation_text'>Презентация проекта</h5>}
            {project.presentation.title && <div className='presentation_container'>
                  <a className='presentation_container' href={project.presentation.title} download>
                    <img className='doc_img' src={'/ProjectPageAssets/file.png'} alt='presentation'></img>
                    <div>
                        <h6 className='presentation_title'>{project.presentation.title.substring(project.presentation.title.lastIndexOf('/') + 1)}</h6>
                        <p className='presentation_size'>{project.presentation.size}</p>
                    </div>
                  </a>
                </div>}
            {project.anotherFiles && <ProjectFiles anotherFiles={project.anotherFiles} />}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectPageTabletAndMobile;
