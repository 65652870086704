import { useState, useEffect } from 'react';

function useWindowWidthPanel() {
  const [width, setWidth] = useState(window.innerWidth);
  const [visible, setVisible] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      setWidth(newWidth);
      setVisible(newWidth < 1200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return visible;
}

export default useWindowWidthPanel;
