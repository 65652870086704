// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.files_container {
  display: flex;
  margin-top: 10px;
  gap: 10px;
  text-decoration: none;
}

.files_container:last-child {
  margin-bottom: 10px;
}

.doc_img {
  width: 60px;
  height: 80px;
  object-fit: cover;
  border-radius: 8px;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/ProjectFiles/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,gBAAgB;EAChB,SAAS;EACT,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB","sourcesContent":[".files_container {\r\n  display: flex;\r\n  margin-top: 10px;\r\n  gap: 10px;\r\n  text-decoration: none;\r\n}\r\n\r\n.files_container:last-child {\r\n  margin-bottom: 10px;\r\n}\r\n\r\n.doc_img {\r\n  width: 60px;\r\n  height: 80px;\r\n  object-fit: cover;\r\n  border-radius: 8px;\r\n  cursor: pointer;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
